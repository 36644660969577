import {
    SET_DUPES, SET_IMPORT_WITH_DUPLICATES
} from "../actionTypes";

const initialState = {
    duplicateConfigurations: [],
    importWithDuplicates: false 
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DUPES: {
            return Object.assign({}, state, {
                duplicateConfigurations: action.payload.dupeList
            })
        }
        case SET_IMPORT_WITH_DUPLICATES: {
            return Object.assign({}, state, {
                importWithDuplicates: action.payload.importWithDuplicates
            })
        }
        default: {
            return state;
        }
    }
};        