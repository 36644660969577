import React, { Component } from 'react';
import { CwLabel, CwImage } from 'cw-widgets';
import './Page.css';

export class Recommended extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let index = 1;
        let rows = [];
        let dragstart_handler = this.props.dragStartHandler;
        let row = "";
        let values = this.props.values;
        for (let item in values) {
            row = <tr key={index} id={item} className="mappingCell" displayname={values[item]} onMouseDown={(ev) => dragstart_handler(ev, item)} >
                <td width="20px"><CwImage className="more" src="https://files.connectwise.com/UI/Icons/v1.0/Application_More.svg" /></td>
                <td><CwLabel className="gridDataText" >{values[item]}</CwLabel></td>
            </tr>;
        
            rows.push(row);
            index++;
        };

        return <div>
            <div className="recommendedInner" id={this.props.id}>
                <CwLabel className="page-header3">{this.props.title}</CwLabel>
            </div>
            <div style={{ "maxHeight": "350px", "overflow": "auto" }}>
                <table id="recommended" className="recommendedGrid" width="100%">
                    <tbody>{rows}</tbody>
                    </table>
            </div>
        </div>
    }
}