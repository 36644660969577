import React, { Component } from 'react';
import { CwComboBox } from 'cw-widgets';
import '../Shared/Page.css';

export class CompanyLinkedDropdown extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(option) {
        this.props.handleOptionChange(this.props.companyName, option.name);
    }

    render() {
        return <div className="linkedCompanyDropdown comboBoxNoUnderline comboBoxNoUnderlineActive comboBoxNoUnderlineFocus">
            <CwComboBox
                placeholder={"Link Site"}
                onChange={this.onChange}
                items={this.props.siteOptions}
                clickAreaToExpand
                cwId={this.props.cwId}
                value={this.props.selectedSite}
            />
        </div>
    }
}