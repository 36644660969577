import React, { Component } from 'react';
import { NavButtons } from '../Shared/NavButtons';
import { PageWrapper } from '../Shared/PageWrapper';
import { CwLabel, CwDialog, CwCheckBox} from 'cw-widgets';
import { connect } from "react-redux";
import { setCompanyAndSiteLink, setSiteLink, resetCompanyLinkingScreen, setDupes, setImportWithDuplicates } from "../../redux/actions";
import '../Shared/Page.css';
import { SearchControl } from '../Shared/SearchControl';
import { CompanySearchDialog } from './CompanySearchDialog';
import { CompanyLinkedDropdown } from './CompanyLinkedDropdown';
import { PageLogoHeader } from '../Shared/PageLogoHeader';
import { DuplicateConfigsDialog } from './DuplicateConfigsDialog';


import initiateAppInsights from "../Shared/AppInsights";

export class CompanyLinking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mappings: {},
            showPopup: false,
            searchDialogResults: null,
            searchCompany: null,
            searchDialogValue: null,
            searchDialogId: null,
            temporarySearchDetails: {},
            siteOptions: {},
            tempCompanyResults: {},
            attemptedSiteFetch: false,
            showDuplicates: false,
            loading: false,
            checkDuplicates: true
        };

        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.checkForwardLinkActive = this.checkForwardLinkActive.bind(this);
        this.handleCompanySearch = this.handleCompanySearch.bind(this);
        this.displaySearchDialog = this.displaySearchDialog.bind(this);
        this.setDialogSearchResults = this.setDialogSearchResults.bind(this);
        this.processLinkForReduxStorage = this.processLinkForReduxStorage.bind(this);
        this.selectCompany = this.selectCompany.bind(this);
        this.setTemporarySearchDetails = this.setTemporarySearchDetails.bind(this);
        this.getPage = this.getPage.bind(this);
        this.getDialogPosition = this.getDialogPosition.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.parentWithIdExists = this.parentWithIdExists.bind(this);
        this.resetSearchState = this.resetSearchState.bind(this);
        this.fetchCompanySites = this.fetchCompanySites.bind(this);
        this.handleSiteSearch = this.handleSiteSearch.bind(this);
        this.handleSiteOptionChange = this.handleSiteOptionChange.bind(this);
        this.cancelDuplicates = this.cancelDuplicates.bind(this);
        this.toggleCheckDuplicates = this.toggleCheckDuplicates.bind(this);
        this.checkDupes = this.checkDupes.bind(this);
        this.fetchDupes = this.fetchDupes.bind(this);
        this.sendAppInsights();
    }

    sendAppInsights() {
        if (this.props.appSettings) {
            if (this.props.appSettings.instrumentationKey) {
                var instrumentationKey = this.props.appSettings.instrumentationKey;
                var appInsights = initiateAppInsights(instrumentationKey);
                appInsights.trackPageView({
                    name: "Company Linking page"
                });
            }
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClick);
    }

    componentDidUpdate(prevProps) {
        if (this.props.uniqueCompanies !== prevProps.uniqueCompanies && !this.state.attemptedSiteFetch) {
            this.searchAutoLinkedCompaniesForSites(this.props.uniqueCompanies);
        }
    }

    cancelDuplicates() {
        this.setState({
            showDuplicates: false
        });
    }

    toggleCheckDuplicates(checkDuplicates) {
        this.setState({
            checkDuplicates: checkDuplicates
        });
    }
    checkForwardLinkActive() {
        var mapped = true;
        let uniqueCompanyNames = Object.keys(this.props.uniqueCompanies);
        if (uniqueCompanyNames.length === 0) {
            mapped = false;
        }
        for (var i = 0; i < uniqueCompanyNames.length; i++) {
            if (!this.props.uniqueCompanies[uniqueCompanyNames[i]].linkedCompany ||
                !this.props.uniqueCompanies[uniqueCompanyNames[i]].linkedSite
            ) {
                mapped = false;
                break;
            }
        }
        return mapped; //true if mappings exists, will expand rules later
    }

    getStartIndex(pageSize, pageNumber) {
        return (pageSize * (pageNumber - 1));
    }

    fetchCompanies(searchCompanyName) {
        var toSend = this.props.manageInfo;

        toSend["searchParameter"] = searchCompanyName;
        return fetch('api/cwRestApi/GetCompaniesByName/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(toSend)
        }).then(res => res.json()).then(response => this.props.handleFetchErrors(response));
    }

    handleCompanySearch(maptoCompanyId, searchCompanyName) {
        var displayError = this.props.displayError;
        let dictionary = this.props.uniqueCompanies;
        this.fetchCompanies(searchCompanyName)
            .then(companyResults => {
                this.setState({
                    tempCompanyResults: companyResults
                });
                if (companyResults && companyResults.length === 1) {
                    let company = companyResults[0];
                    if (dictionary[maptoCompanyId].linkedCompany !== company.name) {
                        this.handleSiteSearch(company.companyId, maptoCompanyId);
                        this.props.setCompanyAndSiteLink(this.processLinkForReduxStorage(maptoCompanyId, company.name, company.site));
                    }
                    this.resetSearchState(maptoCompanyId);
                }
                else {
                    this.displaySearchDialog(maptoCompanyId, searchCompanyName, companyResults);
                }
            }).catch(function (error) {
                console.log(error);
                displayError(error.message, true);
                return null;
            });
    }

    fetchCompanySites(companyId) {
        let manageInfo = this.props.manageInfo
        let headers = this.props.getManageInfoHeader(manageInfo);

        return fetch('api/cwRestApi/GetCompanySites/' + companyId, {
            method: 'GET',
            headers: headers
        }).then(res => res.json()).then(response => this.props.handleFetchErrors(response));
    }

    searchAutoLinkedCompaniesForSites(uniqueCompanies) {
        this.setState({ attemptedSiteFetch: true })
        for (let Company in uniqueCompanies) {
            if (uniqueCompanies[Company].linkedCompanyId) {
                this.handleSiteSearch(uniqueCompanies[Company].linkedCompanyId, Company)
            }
        }
    }

    handleSiteSearch(companyId, companyName) {
        var displayError = this.props.displayError;
        this.fetchCompanySites(companyId)
            .then(siteResults => {
                var results = siteResults;
                let allSiteOptions = this.state.siteOptions;
                allSiteOptions[companyName] = results;
                this.setState({ siteOptions: allSiteOptions });
            }).catch(function (error) {
                console.log(error);
                displayError(error.message, true);
                return null;
            });
    }

    displaySearchDialog(SearchDialogId, searchCompanyName, initialCompanyResults) {
        this.setState({
            showPopup: true,
            searchDialogId: SearchDialogId,
            searchDialogValue: searchCompanyName,
            searchDialogResults: this.convertCompaniesToDataGridRows(initialCompanyResults)
        });
    }

    processLinkForReduxStorage(spreadsheetCompanyName, manageCompanyName, defaultSite) {
        let newCompanyLink = {
            linkedCompany: manageCompanyName,
            linkedSite: defaultSite,
        }
        let companyPayload = {
            id: spreadsheetCompanyName,
            details: newCompanyLink
        }
        return companyPayload;
    }

    //Used to set a company in the search dialog as the selected company link
    selectCompany(companyName, columns) {
        var tempCompResults = this.state.tempCompanyResults;
        var compId = 0;
        for (var compResult of tempCompResults) {
            if (compResult.name === companyName) {
                compId = compResult.companyId;
            }
        }
        this.handleSiteSearch(compId, this.state.searchDialogId);
        this.props.setCompanyAndSiteLink(this.processLinkForReduxStorage(this.state.searchDialogId, companyName, columns["col8"]));
        this.resetSearchState(this.state.searchDialogId);
    }

    convertCompaniesToDataGridRows(rawData) {
        let rows = []
        let rowId = 1;
        rawData.some(function addToList(company) {
            let row = {
                id: rowId,
                col1: company.name,
                col2: company.leadStatus === true ? "Lead" : "Active",
                col3: company.territory ? company.territory.name : null,
                col4: company.type,
                col5: company.market ? company.market.name : null,
                col6: company.status,
                col7: company.enteredDate,
                col8: company.site,
                col9: company.addressLine1 + " " + company.addressLine2,
                col10: company.city,
                col11: company.state,
                col12: company.zip
            };
            rows.push(row);
            rowId++;

            return rowId > 10;
        });
        return rows;
    }

    setDialogSearchResults(searchResults) {
        this.setState({
            searchDialogResults: searchResults
        });
    }

    resetSearchState(maptoCompanyId) {
        let tempSearchFields = this.resetTempSearchField(this.state.temporarySearchDetails, maptoCompanyId);
        this.setState({
            showPopup: false,
            searchDialogResults: null,
            searchDialogId: null,
            searchDialogValue: null,
            searchCompany: null,
            temporarySearchDetails: tempSearchFields
        });
    }

    resetTempSearchField(tempSearchDictionary, spreadsheetCompany) {
        let newTempSearchDictionary = tempSearchDictionary;
        newTempSearchDictionary[spreadsheetCompany] = null;
        return newTempSearchDictionary;
    }

    setTemporarySearchDetails(spreadsheetCompanyName, searchValue) {
        let newSearchDetails = this.state.temporarySearchDetails;
        newSearchDetails[spreadsheetCompanyName] = searchValue;
        this.setState({
            temporarySearchDetails: newSearchDetails,
            searchDialogValue: searchValue,
            searchCompany: spreadsheetCompanyName
        });
    }

    handleSiteOptionChange(companyName, siteName) {
        this.props.setSiteLink(this.processLinkForReduxStorage(companyName, null, siteName));
    }

    getPage(pageDetails, pageSize) {
        let rows = [];
        let isUniqueCompanies = this !== undefined && this.props !== undefined && this.props.uniqueCompanies !== undefined && this.props.uniqueCompanies !== null;
        if (isUniqueCompanies) {
            let index = 0;
            let handleCompanySearch = this.handleCompanySearch;
            let uniqueCompanies = this.props.uniqueCompanies;
            let uniqueCompanyNames = Object.keys(uniqueCompanies);

            let startIndex = this.getStartIndex(pageSize, pageDetails.currentPage);
            let endIndex = startIndex + pageSize;
            let pageNames = uniqueCompanyNames.slice(startIndex, endIndex);

            let tempSearchDetails = this.state.temporarySearchDetails;
            let setTemporarySearchDetails = this.setTemporarySearchDetails;
            let handleSiteOptionChange = this.handleSiteOptionChange;
            let siteOptions = this.state.siteOptions;
            pageNames.forEach(function addToList(companyName) {
                let spreadsheetName = companyName;
                let id = "companyLink" + companyName;
                let text = uniqueCompanies[companyName].linkedCompany || '';
                let site = uniqueCompanies[companyName].linkedSite || '';
                site = site ? site : '';
                let selectedSite = null;
                if (site) {
                    selectedSite = { id: site, name: site };
                }
                let companySiteOptions = siteOptions[companyName] || [];
                let tempSearchValue = tempSearchDetails[companyName];
                let tempSearchProp = tempSearchValue ? tempSearchValue : text;
                let row = <tr key={index} id={companyName} className="linkingRow">
                    <td>{companyName}</td>
                    <td>
                        <SearchControl
                            spreadsheetName={spreadsheetName}
                            id={id}
                            onSearch={handleCompanySearch}
                            returnParams={companyName}
                            searchValue={tempSearchProp}
                            changeSearchValue={setTemporarySearchDetails}
                        />
                    </td>
                    <td>
                        <div className="rectangle" >
                            <CompanyLinkedDropdown
                                companyName={companyName}
                                handleOptionChange={handleSiteOptionChange}
                                siteOptions={companySiteOptions}
                                clickAreaToExpand
                                cwId={id}
                                selectedSite={selectedSite}
                            />
                        </div>
                    </td>
                </tr>;
                rows.push(row);
                index++;
            })
        }

        let page = <div>
            <table id="mappingframe" width="100%">
                <thead>
                    <tr>
                        <th key={'headerCompanyName'} className="linkCompaniesTableHeader" width="33%" />
                        <th key={'headerLinkCompany'} className="linkCompaniesTableHeader" width="33%" />
                        <th key={'headerLinkSite'} className="linkCompaniesTableHeader" width="33%" />
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>;
        return page;
    }

    getDialogPosition() {
        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = (width / 2) - (800 / 2);
        var top = (height / 3) - (400 / 2);

        left = left < 0 ? 0 : left;
        top = top < 0 ? 0 : top;

        return { top, left };
    }

    handleClick(e) {
        if (this.state.searchDialogValue && this.state.searchCompany && !this.state.showPopup) {
            let maptoCompanyId = this.state.searchCompany;
            let searchControlId = "companyLink" + maptoCompanyId;
            let inputId = searchControlId + "Input";
            let serachId = searchControlId + "Search";
            if (e && e.target.getAttribute('id') !== inputId && !this.parentWithIdExists(e.target, serachId)) {
                if (this.props.uniqueCompanies[maptoCompanyId].linkedCompany !== null) {
                    this.props.setCompanyAndSiteLink(this.processLinkForReduxStorage(maptoCompanyId, null, null));
                }
                this.resetSearchState(maptoCompanyId);
            }
        }
    }

    parentWithIdExists(el, id) {
        let found = false;
        while (el !== undefined && el != null && el !== document && !found) {
            if (el.getAttribute('id') === id) {
                found = true;
            }
            el = el.parentElement;
        }
        return found;
    }

    fetchDupes(file, mappings, startLine, manageInfo, uniqueCompanies, handleFetchErrors, displayError) {
        const formData = new FormData();
        formData.append("manageInfo", JSON.stringify(manageInfo));
        let siteColumn = mappings["siteName"] !== null ? mappings["siteName"] : "";
        let serialColumn = mappings["serialNumber"] !== null ? mappings["serialNumber"] : "";
        let uniqueCompaniesArray = [];
        let keys = Object.keys(uniqueCompanies);
        keys.forEach(companySite => {
            uniqueCompaniesArray.push({ "company": uniqueCompanies[companySite].company, "site": uniqueCompanies[companySite].site, "companySite": companySite, "linkedCompany": uniqueCompanies[companySite].linkedCompany, "linkedSite": uniqueCompanies[companySite].linkedSite })
        });
        formData.append("uniqueCompanies", JSON.stringify(uniqueCompaniesArray));
        formData.append(file.name, file);
        return fetch('api/file/CheckForDuplicateConfigs/', {
            method: 'POST',
            headers: {
                'companyColumn': this.props.mapping["companyName"],
                'configColumn': this.props.mapping["name"],
                'serialColumn': serialColumn,
                'firstLineIndex': startLine,
                'siteColumn': siteColumn
            },
            body: formData
        }).then(res => res.json()).then(response => handleFetchErrors(response))
            .catch(function (error) {
                console.log(error);
                displayError(error.message, true);
                return null;
            });
    }

    checkDupes() {
        let serialColumn = this.props.mapping["serialNumber"] !== null ? this.props.mapping["serialNumber"] : "";
 
        if (serialColumn && this.state.checkDuplicates) {
            this.setState({ loading: true });
            let manageInfo = this.props.manageInfo;
            return this.fetchDupes(this.props.file, this.props.mapping, this.props.startLine, manageInfo, this.props.uniqueCompanies, this.props.handleFetchErrors, this.props.displayError)
                .then(duplicates => {
                    this.setState({ loading: false });
                    if (!!duplicates && duplicates.length > 0) {
                        this.setState({ showDuplicates: true });
                        this.props.setDupes(duplicates);
                        return true;
                    }
                    else {
                        return false;
                    }
                });
        }

        let falsePromise = new Promise(function (resolve, reject) {
            resolve(false);
        });

        return falsePromise;
        
    }

    render() {
        let uniqueCompanyNames = Object.keys(this.props.uniqueCompanies);

        let dataSize = uniqueCompanyNames.length;
        let pageSize = 10;

        let duplicateTableHeaders = [{ "name": "configurationName", "displayName": "Configuration Name" }, { "name": "companyName", "displayName": "Company" }, { "name": "serialNumber", "displayName": "Serial Number" }];

        return <div>
            <PageLogoHeader pageNumber="3" />
            <div className="pageWithoutNavButtons">
                <div align='right' id="navbuttons">
                    <NavButtons
                        forwardRoute={'/summary'}
                        forwardLinkActive={this.checkForwardLinkActive}
                        forwardCustom={this.checkDupes}
                        finalButton
                        forwardTooltipText={"Import"}
                        backRoute={'/mappingspage'}
                        backCustom={this.props.resetCompanyLinkingScreen}
                    />
                </div>
                <div id="header">
                    <div className="page-header">
                        <CwLabel>COMPANY LINKING    </CwLabel>
                    </div>
                    <div className="header-summary">Click the fields below to link discovered companies in your import file to Manage companies and sites. Click the import icon to continue. </div>
                </div>
                <div id="saveload"></div>
                <div id="checkDuplicatesDiv" className="renewalsCheckbox">
                    <CwCheckBox
                        name="checkDuplicatesCheckbox"
                        text="Check for duplicate configurations"
                        value={this.state.checkDuplicates}
                        onChange={this.toggleCheckDuplicates}
                        style={{ "font": "14px" }}
                    />
                </div>
                <div id="pagetable">
                    <PageWrapper
                        render={pageDetails => (
                            this.getPage(pageDetails, pageSize)
                        )}
                        dataSize={dataSize}
                        pageSize={pageSize}
                    >
                        <CwLabel className="columnHeader">Discovered Companies</CwLabel>
                    </PageWrapper>
                </div>
                {this.state.showPopup ?
                    <div>
                        <div className="disableBg">
                        </div>
                        <CwDialog
                            header={"Company Search"}
                            width={800}
                            getDialogPosition={this.getDialogPosition}
                        >
                            <CompanySearchDialog
                                searchDialogId={this.state.searchDialogId}
                                searchDialogValue={this.state.searchDialogValue}
                                searchDialogResults={this.state.searchDialogResults}
                                fetchCompanies={this.fetchCompanies}
                                setDialogSearchResult={this.setDialogSearchResults}
                                setTemporarySearchDetails={this.setTemporarySearchDetails}
                                handleSearch={this.handleCompanySearch}
                                selectCompany={this.selectCompany}
                            />
                        </CwDialog>
                    </div>
                    : null
                }
                {this.state.showDuplicates ?
                    <div>
                        <DuplicateConfigsDialog
                            redirectRoute={'/summary'}
                            getDialogPosition={this.getDialogPosition}
                            dialogId="duplicatesDialog"
                            applyDuplicates={this.applyDuplicates}
                            cancelDuplicates={this.cancelDuplicates}
                            importWithDuplicates={this.props.importWithDuplicates}
                            toggleImportWithDuplicates={this.props.setImportWithDuplicates}
                            tableHeaders={duplicateTableHeaders}
                            tableData={this.props.duplicateConfigurations}
                        />
                    </div>
                    : null
                }
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        uniqueCompanies: state.mapping.uniqueCompanies,
        appSettings: state.configs.appSettings,
        configTypeId: state.setup.configTypeId,
        file: state.setup.file,
        mapping: state.mapping.mappings,
        startLine: state.mapping.startLine,
        duplicateConfigurations: state.duplicates.duplicateConfigurations,
        importWithDuplicates: state.duplicates.importWithDuplicates
    };
};

const mapDispatchToProps = {
    setCompanyAndSiteLink,
    setSiteLink,
    setDupes,
    setImportWithDuplicates,
    resetCompanyLinkingScreen
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyLinking);