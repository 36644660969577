import React, { Component } from 'react';
import { CwDialog, CwButton } from 'cw-widgets';
import './Page.css';

export class ConfirmPrompt extends Component {

    render() {
        let confirmButton = <CwButton key={"save"} isStandard onClick={this.props.confirmAction} value={"Save"} />
        let cancelButton = <CwButton key={"cancel"} onClick={this.props.cancelAction} value={"Cancel"} />
        let buttonList = [cancelButton, confirmButton];
        let dialogContent = this.props.dialogContent;

        if (!this.props.showPrompt) {
            return null;
        }

        return <div className="popupOverlay">
            <div>
                <CwDialog
                    buttons={buttonList}
                    width={800}
                    header={""}
                >
                    {dialogContent}
                </CwDialog>
            </div>
        </div>;
    }
}