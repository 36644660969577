import React, { Component } from 'react';
import '../Shared/Page.css';
import { Dropdown } from '../Shared/Dropdown';
import { FileDialog } from '../Shared/FileDialog';
import { SplitFrame } from '../Shared/SplitFrame';
import { PageLogoHeader } from '../Shared/PageLogoHeader'
import { NavButtons } from '../Shared/NavButtons';
import Popup from '../Popup';
import { CwLabel, CwCheckBox } from 'cw-widgets';
import { connect } from "react-redux";
import {
    selectConfigType,
    selectVendor,
    selectFile,
    addDataPreview,
    addConfigTypeQuestions,
    addConfigTypes,
    addVendors,
    toggleRenewable
} from "../../redux/actions";
import XLSX from "xlsx";
import initiateAppInsights from "../Shared/AppInsights";

export class Setup extends Component {
    displayName = Setup.name

    constructor(props) {
        super(props);
        this.state = {
            showPopup: false
        };

        this.handleConfigurationTypeChange = this.handleConfigurationTypeChange.bind(this);
        this.checkForwardLinkActive = this.checkForwardLinkActive.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.popupMessage = this.popupMessage.bind(this);
        this.loadConfigurationTypeQuestions = this.loadConfigurationTypeQuestions.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.loadVendors(props.manageInfo);
        this.loadConfigurationTypes(props.manageInfo);

        this.sendAppInsights();
    }

    sendAppInsights() {
        if (this.props.appSettings) {
            if (this.props.appSettings.instrumentationKey) {
                var instrumentationKey = this.props.appSettings.instrumentationKey;
                var appInsights = initiateAppInsights(instrumentationKey);
                appInsights.trackPageView({
                    name: "Setup page"
                });
            }
        }
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

    popupMessage(title, message) {
        this.setState({
            messageTitle: title,
            messageText: message,
            showPopup: true
        });
    }

    loadConfigurationTypes(manageInfo) {
        var displayError = this.props.displayError;
        fetch('api/cwRestApi/getCompanyConfigurationTypes/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(manageInfo)
        }).then(res => res.json()).then(response => this.props.handleFetchErrors(response))
            .then(data => {
                this.props.addConfigTypes(data);
            }).catch(function (error) {
                console.log(error);
               displayError(error.message, true);
            });
    }

    loadVendors(manageInfo) {
        var displayError = this.props.displayError;
        fetch('api/cwRestApi/getVendors/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(manageInfo)
        }).then(res => res.json()).then(response => this.props.handleFetchErrors(response))
            .then(data => {
                this.props.addVendors(data);
            }).catch(function (error) {
                console.log(error);
                displayError(error.message, true);
            });
    }

    loadConfigurationTypeQuestions(manageInfo, id) {
        var displayError = this.props.displayError;
        var toSend = this.props.manageInfo;
        toSend["searchParameter"] = id;
        fetch('api/cwRestApi/GetConfigurationTypeQuestions/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(toSend)
        }).then(res => res.json()).then(response => this.props.handleFetchErrors(response))
            .then(data => {
                let configTypeQuestions = { id: id, questions: data }
                this.props.addConfigTypeQuestions(configTypeQuestions);
            }).catch(function (error) {
                console.log(error);
                displayError(error.message, true);
            });
    }
    checkForwardLinkActive() {
        if (this.props.configType && this.props.file && this.props.vendor) {
            return true;
        }
        else {
            return false;
        }
    }

    handleConfigurationTypeChange(option) {
        if (option) {
            this.loadConfigurationTypeQuestions(this.props.manageInfo, option.id);
            this.props.selectConfigType(option);
        }
    }

    handleFileChange(fileList) {
        let file = fileList[0];

        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', sheetRows: 10 });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });

            this.props.addDataPreview(data);
        };
        if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
        this.props.selectFile(file);
    }

    render() {
        let configurationTypeId = "ConfigurationType";
        let configurationTypeHeader = "Configuration Type *";
        let configurationTypeText = "Loading configuration types...";
        let configurationTypeWatermark = "Select Manage Configuration Type";

        let vendorId = "Vendor";
        let vendorHeader = "Vendor *";
        let vendorText = "Loading vendors...";
        let vendorWatermark = "Select Vendor Name";

        let leftFrame = <Dropdown
            id={vendorId}
            header={vendorHeader}
            options={this.props.vendors}
            handleOptionChange={this.props.selectVendor}
            selection={this.props.vendor}
            loadingText={vendorText}
            loading={this.props.vendorsLoading}
            placeholderText={vendorWatermark}
        />

        let rightFrame = <Dropdown
            id={configurationTypeId}
            header={configurationTypeHeader}
            options={this.props.configTypes}
            handleOptionChange={this.handleConfigurationTypeChange}
            selection={this.props.configType}
            loadingText={configurationTypeText}
            loading={this.props.configTypesLoading}
            placeholderText={configurationTypeWatermark}
        />
        return (
            <div>
                <PageLogoHeader pageNumber="1" />
                <div className="pageWithoutNavButtons">             
                    <div id="setupscreennavbuttons" align="right">
                        <NavButtons
                            forwardRoute={'/mappingspage'}
                            forwardLinkActive={this.checkForwardLinkActive}
                            disableBackButton
                        />
                    </div>
                    <div id="header">
                        <div className="page-header">
                            <CwLabel>CONFIGURATION TYPE</CwLabel>
                        </div>
                        <div className="header-summary">The Configuration Type screen lets you import a list of configurations from a single vendor and assign one configuration type to each list. 
                            Select the Vendor and Manage Configuration Type you want to assign to imported configurations.
                        </div>
                    </div>
                    <div className="vendorAndConfigSelection">
                        <SplitFrame
                            tableId="dropdownsframe"
                            leftId="vendorframe"
                            rightId="configurationtypeframe"
                            leftChildren={leftFrame}
                            rightChildren={rightFrame}
                        />
                    </div>
                    <div className="renewalsCheckboxTitle">
                        <CwLabel>Select to indicate that imported items expire and renew. </CwLabel>
                    </div>
                    <div className="setupscreenlayout renewalsCheckbox">                    
                        <CwCheckBox
                            name="renewalsCheckbox"
                            text="Renewable"
                            value={this.props.renewable}
                            onChange={this.props.toggleRenewable}
                            style={{ "font": "14px" }}
                        />
                    </div>
                    <div className="upload-configuration">
                        <CwLabel className="upload-configuration-label">Upload Configurations</CwLabel>
                        <FileDialog
                            handleFileChange={this.handleFileChange}
                            selectedFileName={this.props.file ? this.props.file.name : null}
                            showPopup={this.popupMessage}
                        />
                    </div>
                    {this.state.showPopup ?
                        <Popup
                            title={this.state.messageTitle}
                            text={this.state.messageText}
                            closePopup={this.togglePopup}
                        />
                        : null
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        configType: state.setup.configType,
        vendor: state.setup.vendor,
        file: state.setup.file,
        configTypes: state.setup.configTypes,
        configTypeQuestions: state.setup.configTypeQuestions,
        vendors: state.setup.vendors,
        configTypesLoading: state.setup.configTypesLoading,
        vendorsLoading: state.setup.vendorsLoading,
        renewable: state.setup.renewable,
        appSettings: state.configs.appSettings,
        configsLoaded: state.configs.configsLoaded
    };
};

const mapDispatchToProps = {
    selectConfigType,
    selectVendor,
    selectFile,
    addDataPreview,
    addConfigTypeQuestions,
    addConfigTypes,
    addVendors,
    toggleRenewable
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
    )(Setup);