import React from 'react';
import './FileDialog.css';
import { CwLabel, CwButton } from 'cw-widgets';


export class FileDialog extends React.Component {
    constructor(props) {
        super(props);
        this.handleFiles = this.handleFiles.bind(this);
        this.checkValidFileTypes = this.checkValidFileTypes.bind(this);
        this.handleBrowseClick = this.handleBrowseClick.bind(this);
        this.handleInputSelect = this.handleInputSelect.bind(this);

        this.state = {
            showPopup: false,
            docIcon: "https://files.connectwise.com/UI/Icons/v1.0/Document.svg"
        };
    }
    checkValidFileTypes(value) {
        var validTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/csv'
        ];

        if (typeof value != 'undefined' && !validTypes.includes(value[0].type)) {
            return false;
        }

        if (typeof value != 'undefined' && value[0].name.includes(".xls")) {
            this.setState({
                docIcon: "https://files.connectwise.com/UI/Icons/v1.0/Action_File_Excel.svg"
            });
        }
        else {
            this.setState({
                docIcon: "https://files.connectwise.com/UI/Icons/v1.0/Document.svg"
            });
        }

        return true;
    }
    handleFiles(file) {
        if (this.checkValidFileTypes(file) !== true) {
            this.props.showPopup('Invalid File Type', 'Only .csv and .xls type files are supported for the import. Please select a file of this type and try again.');
        }
        else {
            this.props.handleFileChange(file);
        }
    }

    handleBrowseClick() {
        this.inputField.click();
    }

    handleInputSelect = (event) => {
        // Update
        if (!event.target.files || event.target.files.length < 1) {
            return;
        }
        let files = event.target.files;

        this.handleFiles(files)
    }

    render() {
        let fileMessage = this.props.selectedFileName ? this.props.selectedFileName : "";

        return (
            <div className="fileDialog">
                <div className={"fileLabel"}>
                    <CwLabel>Upload File</CwLabel>
                </div>
                <input
                    className={"fileInput"}
                    accept='.csv, .xls, .xlsx'
                    type='file'
                    tabIndex='-1'
                    ref={(input) => this.inputField = input}
                    onChange={this.handleInputSelect}
                />
                <div className="fileLine">
                    <div className="rectangle">
                        <img
                            className="fileImage"
                            src={this.state.docIcon}
                            alt=""
                        />
                        <input type="text" className="fileDialogLabel" id="fileLableOutline" placeholder="Upload CSV, XLS, or XLSX file" value={fileMessage} readOnly></input>

                    </div>
                </div>
                <CwButton
                    value="BROWSE"
                    isStandard
                    onClick={this.handleBrowseClick}
                />
            </div>
        );
    }
}
export default FileDialog;