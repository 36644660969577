import React, { Component } from 'react';
import { NavButtons } from '../Shared/NavButtons';
import { PageLogoHeader } from '../Shared/PageLogoHeader'
import initiateAppInsights from "../Shared/AppInsights";
import { Dropdown } from '../Shared/Dropdown';
import { SplitFrame } from '../Shared/SplitFrame';
import { ConfirmPrompt } from '../Shared/ConfirmPrompt';
import { connect } from "react-redux";
import { Mapping } from './Mapping';
import { CwLabel } from 'cw-widgets';
import {
    setMapping,
    removeMapping,
    loadMappings,
    setStartLine,
    addUniqueCompanies,
    resetMappingScreen,
    selectMappingTemplate
} from "../../redux/actions";
import './Mapping.css';
import '../Shared/Page.css';
import '../Shared/SplitFrame.css';

import { CwTextField, CwButton, CwImage } from 'cw-widgets';


export class MappingsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            skipHeaders: false,
            firstLineIndex: 0,
            templateTextEntry: this.props.templateName ? this.props.templateName : "",
            templateSelection: this.props.templateName ? { id: this.props.templateName, name: this.props.templateName } : null,
            templates: [],
            loadingTemplates: true,
            templateLoadError: false,
            showPrompt: false
        };        
        this.getRecommendedValues = this.getRecommendedValues.bind(this);
        this.getAdditionalValues = this.getAdditionalValues.bind(this);
        this.postFileForProcessing = this.postFileForProcessing.bind(this);
        this.checkForwardLinkActive = this.checkForwardLinkActive.bind(this);

        this.onDropWindowHandler = this.onDropWindowHandler.bind(this);

        this.sendAppInsights();
        this.getSaveElement = this.getSaveElement.bind(this);
        this.selectTemplate = this.selectTemplate.bind(this);
        this.createNameLine = this.createNameLine.bind(this);
        this.createSaveButton = this.createSaveButton.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.validateChars = this.validateChars.bind(this);
        this.saveOnClick = this.saveOnClick.bind(this);
        this.getTemplates(props.manageInfo, props.displayError, props.handleFetchErrors);
        this.processTemplates = this.processTemplates.bind(this);
        this.addNewTemplateToState = this.addNewTemplateToState.bind(this);
        this.postNewTemplate = this.postNewTemplate.bind(this);
        this.newSelectedTemplate = this.newSelectedTemplate.bind(this);
        this.activateConfirmPrompt = this.activateConfirmPrompt.bind(this);
        this.duplicateMappingCancelAction = this.duplicateMappingCancelAction.bind(this);
        this.duplicateMappingConfirmAction = this.duplicateMappingConfirmAction.bind(this);
        this.loadMappingFromDatabase = this.loadMappingFromDatabase.bind(this);
        this.fetchMappingTemplate = this.fetchMappingTemplate.bind(this);
    }

    sendAppInsights() {
        if (this.props.appSettings) {
            if (this.props.appSettings.instrumentationKey) {
                var instrumentationKey = this.props.appSettings.instrumentationKey;
                var appInsights = initiateAppInsights(instrumentationKey);
                appInsights.trackPageView({
                    name: "Mapping page"
                });
            }
        }
    }

    getTemplates(manageInfo, displayError, handleFetchErrors) {
        if (this.props.getManageInfoHeader) {
            let headers = this.props.getManageInfoHeader(manageInfo);
            fetch('api/database/GetSavedMappings/' + this.props.configTypeId, {
                method: 'GET',
                headers: headers
            }).then(res => res.json())
                .then(response => handleFetchErrors(response))
                .then(data => {
                    var templateList = data;
                    templateList.unshift('');
                    this.processTemplates(templateList);
                }).catch (function (error) {
                    console.log(error);
                    displayError(error.message, true);
                    this.setState({templateLoadError: true})
                });
        }
    }

    processTemplates(templates) {
        var result = [];
        for (var index in templates) {
            result.push({ name: templates[index], id: templates[index] });
        }
        this.setState({
            templates: result,
            loadingTemplates: false
        });
    }

    onDropWindowHandler(ev) {
        ev.preventDefault();
        document.getElementById("mappingframe").style.cursor = "auto";
    }


    postFileForProcessing() {
        const formData = new FormData();
        let file = this.props.file;
        let siteColumn = this.props.mapping["siteName"] !== null ? this.props.mapping["siteName"] : "";
        formData.append(file.name, file);
         return fetch('api/file/GetUniqueValuesFromColumn/', {
            method: 'POST',
            headers: {
                'companyColumn': this.props.mapping["companyName"],
                'siteColumn': siteColumn,
                'firstLineIndex': this.props.startLine
            },
            body: formData
        }).then(res => res.json()).then(response => this.props.handleFetchErrors(response))
            .then(companyData => {
                let uniqueCompanies = this.parseCompanies(companyData);
                this.checkCompaniesForValidLinks(Object.keys(uniqueCompanies))
                    .then(linkData => {
                        let uniqueCompaniesWithLinkedData = this.mergeCompanyAndLinkData(linkData, uniqueCompanies);
                        this.props.addUniqueCompanies(uniqueCompaniesWithLinkedData);
                    })
                return false;
            }).catch(function (error) {
                console.log(error);
                this.props.displayError(error.message, true);
            });
    }

    mergeCompanyAndLinkData(linkData, uniqueCompanies) {
        for (let index in linkData) {
            let link = linkData[index];
            if (uniqueCompanies[link.companySite]) {
                uniqueCompanies[link.companySite].linkedCompany = link.linkedCompany;
                uniqueCompanies[link.companySite].linkedSite = link.linkedSite;
                uniqueCompanies[link.companySite].linkedCompanyId = link.id
            }
        }
        return uniqueCompanies;
    }

    parseCompanies(companies) {
        var companiesOutput = {};
        for (var i = 0; i < companies.length; i++) {
            let hasSite = (companies[i].site === undefined || companies[i].site == null || companies[i].site.length <= 0) ? false : true;
            let combinedName = hasSite ? companies[i].company + ' - ' + companies[i].site : companies[i].company;
            companiesOutput[combinedName] = {
                site: companies[i].site,
                company: companies[i].company,
            };
        }
        return companiesOutput;
    }

    checkCompaniesForValidLinks(companiesAndSites) {
        let manageInfo = this.props.manageInfo;
        let manageInfoHeaders = this.props.getManageInfoHeader(manageInfo);
        let handleFetchErrors = this.props.handleFetchErrors;
        let displayError = this.props.displayError;

        let getCompanyLinksRequest = {
            importMappingName: this.props.templateName,
            configurationTypeId: this.props.configTypeId,
            companiesAndSites: companiesAndSites
        }

        return fetch('api/database/GetCompanyLinkDetails/', {
            method: 'POST',
            headers: manageInfoHeaders,
            body: JSON.stringify(getCompanyLinksRequest)
        })
            .then(res => res.json())
            .then(response => handleFetchErrors(response))
            .catch(function (error) {
                console.log(error);
                displayError(error.message, true);
            });
    }

    getRecommendedValues() {
        let recommendedValues = {
            "companyName": "Company*",
            "name": "Configuration Name*",
            "siteName": "Site",
            "manufacturerPartNumber": "Manufacturer Part Number",
            "manufacturerName": "Manufacturer",
            "warrantyExpirationDate": "Expiration Date",
            "serialNumber": "Serial Number"
        };

        return recommendedValues;
    }

    getAdditionalValues() {
        let tempadditionalvalues = {
            "installationDate": "Install Date",
            "installedBy": "Installed by",
            "purchaseDate": "Purchase Date",
            //"locationId": "Location", // cutomer won't be able to map this
            "modelNumber": "Model Number",
            "tagNumber": "Tag number",
            "notes": "Notes",
            "vendorNotes": "Vendor Notes"
        };

        if (this.props.configTypeQuestions) {
            this.props.configTypeQuestions.forEach(item => {
                tempadditionalvalues["question_" + item.id] = item.question
            });
        };

        return tempadditionalvalues;
    }
       
    checkForwardLinkActive() {
        if (this.props.mapping["companyName"] && this.props.mapping["name"] && this.props.startLine < this.props.dataPreview.length) {
            return true;
        }
        else {
            return false;
        }
    }
        
    selectTemplate() {
        var selectId = "selectTemplate";
        var selectText = "Loading Mapping Templates...";
        var noTemplatesText = "No Saved Mappings";
        var selectTemplateWatermark = "Select a Mapping Template";

        let noTemplates = !this.state.loadingTemplates && !(this.state.templates.length > 1);
        let dropdownWatermark = noTemplates ? noTemplatesText : selectTemplateWatermark;
        let returnTemplate = <Dropdown
            id={selectId}
            options={this.state.templates}
            handleOptionChange={this.newSelectedTemplate}
            selection={this.state.templateSelection}
            loadingText={selectText}
            placeholderText={dropdownWatermark}
            loading={this.state.loadingTemplates}
        />
        return returnTemplate;
    }

    newSelectedTemplate(selectedTemplate, loadFromDatabase = true) {
        let name = selectedTemplate.name === null || selectedTemplate.name.length === 0 ? "" : selectedTemplate.name;
        this.nameChange(name);
        this.props.selectMappingTemplate(name);

        this.setState({
            templateSelection: selectedTemplate,
            templateTextEntry: selectedTemplate.name
        });

        if (name !== "" && loadFromDatabase) {
            this.loadMappingFromDatabase(name, this.props.dataPreview);
        }
    }

    loadMappingFromDatabase(selectedTemplateName, dataPreview) {
        let setStartLine = this.props.setStartLine;
        let loadMappings = this.props.loadMappings;
        let constructValidTemplate = this.constructValidTemplate;

        this.fetchMappingTemplate(selectedTemplateName)
            .then(function (template) {
                setStartLine(template.startLineNumber);
                let validTemplate = constructValidTemplate(template.propertyMap, dataPreview);
                loadMappings(validTemplate);
            });
    }

    constructValidTemplate(propertyMap, dataPreview) {
        //calculate widest row of spreadsheet
        function countforeach(value) {
            let length = value.length;
            if (length > maxLength) {
                maxLength = length;
            }
        }
        let maxLength = 0;
        dataPreview.forEach(countforeach);

        let validTemplate = {}
        for (let mapping in propertyMap) {
            let colNumber = propertyMap[mapping];

            let intColNumber = colNumber.slice(3)
            if (intColNumber < maxLength) {
                validTemplate[mapping] = propertyMap[mapping];
            }
        }

        return validTemplate;
    }

    fetchMappingTemplate(selectedTemplateName) {
        let manageInfo = this.props.manageInfo;
        let configTypeId = this.props.configTypeId;
        let apiUrl = configTypeId + "/" + selectedTemplateName;
        let displayError = this.props.displayError;
        if (this.props.getManageInfoHeader) {
            let headers = this.props.getManageInfoHeader(manageInfo);
             return fetch('api/database/GetSavedMappingDetails/' + apiUrl, {
                method: 'GET',
                headers: headers
            }).then(res => res.json())
                .then(response => this.props.handleFetchErrors(response))
                .catch(function (error) {
                    console.log(error);
                    displayError(error.message, true);
                });
        }
    }

    createNameLine() {
        var nameId = "nameTemplate";
        var nameTemplateWatermark = "Enter Template Name";
        let nameTemplate = <CwTextField
            id={nameId}
            onChange={this.nameChange}
            value={this.state.templateTextEntry}
            placeholder={nameTemplateWatermark}
            onKeyPress={(e) => this.validateChars(e)}
        />
        return nameTemplate;
    }

    nameChange(newName) {
        this.setState({
            templateTextEntry: newName
        })
    }

    validateChars(e) {
        const regex = /^[0-9a-zA-Z _]+$/;
        if (!regex.test(e.key)) {
            e.preventDefault();
        }
    }

    createSaveButton() {
        let saveProperties = {};
        let saveDisabled = !this.enableSaveButton();
        saveProperties.onClick = this.saveOnClick;
        saveProperties.disable = saveDisabled;
        saveProperties.className = saveDisabled ? "saveButtonDisabled" : "saveButtonEnabled";
        saveProperties.value = <CwImage className="saveButtonImage" src="https://files.connectwise.com/UI/Icons/v1.0/Action_Save.svg" />;
        let saveButton =
            <div>
                <CwButton {...saveProperties} id="saveMappingsBtn" disabled={saveDisabled}/>
                <p className="saveText">Save</p>
            </div>;        
        return saveButton;
    }
    
    enableSaveButton() {
        if (this.state.templateTextEntry && this.props.mapping["companyName"] && this.props.mapping["name"] && !!this.state.templateTextEntry.trim()) {
            return true;
        }
        else {
            return false;
        }
    }

    saveOnClick() {
        //if the template name matches an existing template name show a pop-up asking stuff
        var existingTemplates = this.state.templates;
        var newTemplateName = this.state.templateTextEntry;
        let sanitizedTemplateName = newTemplateName.trim().replace(/[^0-9a-zA-Z_ ]/g, '').substring(0, 100);;
        //find duplicates
        let duplicate = false;
        if (sanitizedTemplateName.length > 0) {
            if (existingTemplates) {
                duplicate = this.checkTemplatesForDuplicate(sanitizedTemplateName, existingTemplates);
            }

            if (duplicate) {
                this.activateConfirmPrompt(sanitizedTemplateName);
            }
            else {
                this.postNewTemplate(sanitizedTemplateName);
                this.addNewTemplateToState(sanitizedTemplateName);
            }
        }
    }

    checkTemplatesForDuplicate(targetTemplateName, templates) {
        for (var template in templates) {
            if (templates[template].name === targetTemplateName) {
                return true;
            }
        }

        return false;
    }

    addNewTemplateToState(name) {
        let sortedTemplates = this.state.templates;

        sortedTemplates.push({ name: name, id: name });

        sortedTemplates.sort(function (a, b) {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            return nameA.localeCompare(nameB);
        })

        this.setState({
            templates: sortedTemplates
        });

        this.newSelectedTemplate({ name: name, id: name }, false)
    }

    postNewTemplate(name) {
        let manageInfo = this.props.manageInfo
        let headers = this.props.getManageInfoHeader(manageInfo);
        let mapdata = {
            "importMappingName": name,
            "companyIdentifier": manageInfo.companyId,
            "configurationTypeId": this.props.configTypeId,
            "propertyMap": this.props.mapping,
            "startLineNumber": this.props.startLine
        }
        fetch('api/database/SaveConfigurationMapping/', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(mapdata)
        }).then(res => res.json())
            .then(response => this.props.handleFetchErrors(response))
            .catch(function (error) {
                console.log(error);
                this.props.displayError(error.message, true);
            });

    }

    getSaveElement() {

        var selectedTemplate = this.selectTemplate();
        var nameTemplate = this.createNameLine();
        var saveButton = this.createSaveButton();     

        let selectionTable = <SplitFrame
            tableId="selectionTable"
            leftId="selectMapping"
            rightId="nameMapping"
            leftChildren={selectedTemplate}
            rightChildren={nameTemplate}
        />;

        return (
            <div id="MappingTemplate">
                <SplitFrame
                    tableId="templateTable"
                    leftId="mappingTemplate"
                    rightId="saveMapping"
                    leftChildren={selectionTable}
                    rightChildren={saveButton}
                />
            </div>
        );
    }

    activateConfirmPrompt(name) {
        this.setState({
            showPrompt: true,
            temporaryMappingName: name
        })
    }

    duplicateMappingConfirmAction() {
        let mappingName = this.state.templateTextEntry;
        this.postNewTemplate(mappingName);
        this.setState({ showPrompt: false });
    }

    duplicateMappingCancelAction() {
        this.setState({ showPrompt: false });
    }

    render() {
        var recommended = this.getRecommendedValues();
        var additionalFields = this.getAdditionalValues();
        var saveElement = this.getSaveElement();

        let duplicateMappingDialogText = "A mapping with this name already exists. Click Save to overwrite the existing mapping, or Cancel to close this dialog and enter a different name";

        return (
            <div>
                <PageLogoHeader pageNumber="2" />
                <div className="pageWithoutNavButtons" onMouseUp={this.onDropWindowHandler} >
                    <div align='right' id="navbuttons">
                        <NavButtons
                            forwardRoute={'/companylinking'}
                            forwardCustom={this.postFileForProcessing}
                            forwardLinkActive={this.checkForwardLinkActive}
                            backRoute={'/setup'}
                            backCustom={this.props.resetMappingScreen}
                        />
                    </div>
                    <div className="pageBody">
                        <div id="header">
                            <div className="page-header">
                                <CwLabel>PREVIEW AND MAPPING</CwLabel>
                            </div>
                            <div className="header-summary" id="mappingPageSummary">Drag and drop fields from the panel to assign Manage fields to columns in your import file. You can assign multiple fields to a single column, if needed. You must map required fields to continue. </div>
                        </div>
                        <div id="saveload" />
                        {saveElement}
                        <Mapping
                            {...this.props}
                            recommended={recommended}
                            additionalFields={additionalFields}
                        />
                    </div>
                </div>
                <ConfirmPrompt
                    confirmAction={this.duplicateMappingConfirmAction}
                    cancelAction={this.duplicateMappingCancelAction}
                    showPrompt={this.state.showPrompt}
                    dialogContent={duplicateMappingDialogText}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    let id = state.setup.configTypeId;
    return {
        configTypeQuestions: state.setup.configTypeQuestionDictionary[id],
        dataPreview: state.setup.dataPreview,
        file: state.setup.file,
        mapping: state.mapping.mappings,
        startLine: state.mapping.startLine,
        appSettings: state.configs.appSettings,
        configTypeId: state.setup.configTypeId,
        templateName: state.mapping.templateName
    };
};

const mapDispatchToProps = {
    setMapping,
    removeMapping,
    loadMappings,
    setStartLine,
    addUniqueCompanies,
    resetMappingScreen,
    selectMappingTemplate
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MappingsPage);

