import {
    SELECT_CONFIGURATION_TYPE,
    SELECT_VENDOR,
    SELECT_FILE,
    ADD_DATA_PREVIEW,
    ADD_CONFIGURATION_TYPE_QUESTIONS,
    ADD_CONFIGURATION_TYPES,
    ADD_VENDORS,
    TOGGLE_RENEWABLE,
    RESET_SETUP_SCREEN
} from "../actionTypes";

const initialState = {
    configType: null,
    configTypeId: null,
    configTypeQuestionDictionary: {},
    vendor: null,
    file: null,
    dataPreview: [],
    configTypes: [],
    vendors: [],
    configTypesLoading: true,
    vendorsLoading: true,
    renewable: true
};


export default function (state = initialState, action) {
    switch (action.type) {
        case SELECT_CONFIGURATION_TYPE: {
            return Object.assign({}, state, {
                configType: action.payload.configType,
                configTypeId: action.payload.configType.id
            })
        }
        case SELECT_VENDOR: {
            return Object.assign({}, state, {
                vendor: action.payload.vendor
            })
        }
        case SELECT_FILE: {
            return Object.assign({}, state, {
                file: action.payload.file,
            })
        }
        case ADD_DATA_PREVIEW: {
            return Object.assign({}, state, {
                dataPreview: action.payload.dataPreview
            })
        }
        case ADD_CONFIGURATION_TYPE_QUESTIONS: {
            let id = action.payload.configTypeQuestions.id;
            let questions = action.payload.configTypeQuestions.questions;
            return {
                ...state,
                configTypeQuestionDictionary: {
                    ...state.configTypeQuestionDictionary,
                    [id]: questions
                }
            }
        }
        case ADD_CONFIGURATION_TYPES: {
            return Object.assign({}, state, {
                configTypes: action.payload.configTypes,
                configTypesLoading: false
            })
        }
        case ADD_VENDORS: {
            return Object.assign({}, state, {
                vendors: action.payload.vendors,
                vendorsLoading: false
            })
        }
        case TOGGLE_RENEWABLE: {
            return Object.assign({}, state, {
                renewable: action.payload.renewable
            })
        }
        case RESET_SETUP_SCREEN: {
            return Object.assign({}, state, {
                mappings: {},
                mappingHeaders: {},
                uniqueCompanies: {},
                startLine: 0,
                configType: null,
                configTypeId: null,
                configTypes: [],
                configTypeQuestionDictionary: {},
                file: null,
                dataPreview: [],
                vendor: null,
                vendors: [],
                configTypesLoading: true,
                vendorsLoading: true,
                renewable: true
            })
        }
        default: {
            return state;
        }
    }
}