import React from 'react';
import './SplitFrame.css';

export class SplitFrameScrollPadding extends React.Component {

    render() {
        let leftWidth = this.props.leftFrameWidth || "50%";
        let rightWidth = this.props.rightFrameWidth || "50%";
        let frameHeight = this.props.frameHeight || "auto";

        return <table id={this.props.tableId} width="100%" height={frameHeight} className="white-background">
            <tbody>
                <tr key="row0">
                    <td id={this.props.leftId} width={leftWidth} className="twoColumnLayout">
                        {this.props.leftChildren}
                    </td>
                    <td id={this.props.rightId} width={rightWidth} className="twoColumnLayout">
                        {this.props.rightChildren}
                    </td>
                    <td id="scrollPadding">
                    </td>
                </tr>
            </tbody>
        </table>
    }
}