import React, { Component } from 'react';
import { CwImage, CwLoader } from 'cw-widgets';
import { connect } from "react-redux";
import '../Shared/Page.css';
import '../Shared/SplitFrame.css';
import { Redirect } from 'react-router';
import { resetSetupScreen, resetMappingScreen} from "../../redux/actions";

import initiateAppInsights from "../Shared/AppInsights";

export class Summary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            jobId: null,
            displaySpinner: true,
            jobStatus: "pending",
            errorFile: null,
            redirect: false,
            redirectRoute: ''
        };

        this.saveCompanyLinkings = this.saveCompanyLinkings.bind(this);
        this.createConfigs = this.createConfigs.bind(this);
        this.displaySpinner = this.displaySpinner.bind(this);

        this.startUpdateTimer = this.startUpdateTimer.bind(this);
        this.endUpdateTimer = this.endUpdateTimer.bind(this);
        this.fetchJobStatus = this.fetchJobStatus.bind(this);
        this.evaluateJobStatus = this.evaluateJobStatus.bind(this);
        this.handleSpreadsheetDownload = this.handleSpreadsheetDownload.bind(this);
        this.handleFirstPageReturn = this.handleFirstPageReturn.bind(this);
        this.sendAppInsights();
    }

    sendAppInsights() {
        if (this.props.appSettings) {
            if (this.props.appSettings.instrumentationKey) {
                var instrumentationKey = this.props.appSettings.instrumentationKey;
                var appInsights = initiateAppInsights(instrumentationKey);
                appInsights.trackPageView({
                    name: "Summary Screen"
                });
            }
        }
    }

    displaySpinner(show) {
        this.setState({ displaySpinner: show });
    }

    componentDidMount() {
        this.saveCompanyLinkings();
    }

    componentWillUnmount() {
        this.endUpdateTimer();
    }

    startUpdateTimer() {
        let intervalInSeconds = 15;
        this.timerId = setInterval(
            () => this.fetchJobStatus(),
            intervalInSeconds * 1000
        );
    }

    endUpdateTimer() {
        clearInterval(this.timerId);
    }

    saveCompanyLinkings() {
        let manageInfo = this.props.manageInfo
        let headers = this.props.getManageInfoHeader(manageInfo);
        let uniqueCompanies = this.props.uniqueCompanies;
        let uniqueCompaniesArray = [];
        let keys = Object.keys(this.props.uniqueCompanies);
        keys.forEach(companySite => {
            uniqueCompaniesArray.push({ "company": uniqueCompanies[companySite].company, "site": uniqueCompanies[companySite].site, "companySite": companySite, "linkedCompany": uniqueCompanies[companySite].linkedCompany, "linkedSite": uniqueCompanies[companySite].linkedSite })
        });

        let toSend = {
            "importMappingName": this.props.templateName,
            "configurationTypeId": this.props.configTypeId,
            "companyMaps": uniqueCompaniesArray
        }

        fetch('api/database/SaveCompanyLinks/', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(toSend)
        }).then(res => res.json())
            .then(response => this.props.handleFetchErrors(response))
            .then(() => {
                this.createConfigs();
            })
            .catch(function (error) {
                console.log(error);
                this.createConfigs();
            });
    }

    fetchJobStatus() {
        let jobId = this.state.jobId;
        let displayError = this.props.displayError;
        let manageInfo = this.props.manageInfo
        let headers = this.props.getManageInfoHeader(manageInfo);

        fetch('api/importToolAPI/GetImportStatus/' + jobId, {
            method: 'GET',
            headers: headers
        }).then(res => res.json()).then(response => this.props.handleFetchErrors(response))
            .then(data => {
                this.evaluateJobStatus(data);
            }).catch(function (error) {
                console.log(error);
                displayError(error.message, true);
            });
    }

    evaluateJobStatus(data) {
        switch (data.jobStatus) {
            case "Pending":
                this.setState({
                    jobStatus: "Pending"
                });
                break;
            case "Processing":
                this.setState({
                    jobStatus: "Processing"
                });
                break;
            case "Completed":
                this.setState({
                    jobStatus: "Completed"
                });
                this.endUpdateTimer();
                break;
            case "CompletedWithErrors":
                this.setState({
                    jobStatus: "CompletedWithErrors",
                    errorFile: data.errorFile
                });
                if (data.errorFile) {
                    this.endUpdateTimer();
                }
                break;
            case "Failed":
                this.setState({
                    jobStatus: "Failed"
                });
                if (data.errorFile || data.jsonConversionStatus === "Failed") {
                    this.endUpdateTimer();
                }
                break;
            default:
                this.props.displayError("Job status was not recognized. User will need to check jobs screen", false);
                this.endUpdateTimer();
        }
    }

    createConfigs() {
        const formData = new FormData();
        formData.append("file", this.props.file);
        formData.append("manageInfo", JSON.stringify(this.props.manageInfo));
        formData.append("vendor", this.props.vendor.name);
        formData.append("configType", JSON.stringify(this.props.selectConfigType));
        formData.append("configTypeQuestions", JSON.stringify(this.props.configTypeQuestions));

        let mappingArray = [];
        let mapping = this.props.mapping;
        let keys = Object.keys(this.props.mapping);
        keys.forEach(propName => {
            mappingArray.push({ "propName": propName, "colIndex": mapping[propName].substr(3) })
        });
        formData.append("mapping", JSON.stringify(mappingArray));
        formData.append("renewable", JSON.stringify(this.props.renewable));
        let uniqueCompanies = this.props.uniqueCompanies;
        let uniqueCompaniesArray = [];
        keys = Object.keys(this.props.uniqueCompanies);
        keys.forEach(companySite => {
            uniqueCompaniesArray.push({ "company": uniqueCompanies[companySite].company, "site": uniqueCompanies[companySite].site, "companySite": companySite, "linkedCompany": uniqueCompanies[companySite].linkedCompany, "linkedSite": uniqueCompanies[companySite].linkedSite })
        });
        formData.append("uniqueCompanies", JSON.stringify(uniqueCompaniesArray));
        formData.append("firstLineIndex", this.props.startLine);
        let displayError = this.props.displayError;
        let displaySpinner = this.displaySpinner;

        if (!this.props.importWithDuplicates) {
            formData.append("duplicateCompanies", JSON.stringify(this.props.duplicateConfigurations));
        }

        fetch('api/importToolAPI/CreateCompanyConfigurations/', {
            method: 'POST',
            body: formData
        }).then(res => res.json()).then(response => this.props.handleFetchErrors(response))
            .then(data => {
                this.setState({
                    jobId: data.importId
                });
                displaySpinner(false);
                this.startUpdateTimer();
            }).catch(function (error) {
                console.log(error);
                displayError(error.message, true);
                displaySpinner(false);
            });
    }

    handleSpreadsheetDownload() {
        let errorLink = this.state.errorFile
        let manageInfo = this.props.manageInfo

        let headers = this.props.getManageInfoHeader(manageInfo);
        fetch(errorLink, {
            method: 'GET',
            headers: headers
        }).then(res => res.json()).then(response => {
            if (response.url) {
                window.open(response.url, '_blank');
            }
        })
    }

    handleFirstPageReturn() {
        if (this.props.resetSetupScreen) {
            this.props.resetSetupScreen();
        }
        if (this.props.resetMappingScreen) {
            this.props.resetMappingScreen();
        }
        let route = this.props.forwardRoute ? this.props.forwardRoute : '/';
        this.setState({ redirect: true, redirectRoute: route })
    }

    render() {
        let imageSource = null;
        let textHeader = null;
        let textRows = [];
        let showDownloadLink = false;
        switch (this.state.jobStatus) {
            case "Failed":
            case "CompletedWithErrors":
                imageSource = "https://files.connectwise.com/UI/Icons/v1.0/Status_CredsMissing.svg";
                showDownloadLink = this.state.errorFile ? true : false;
                textHeader = "Uh oh! Your import completed with errors";
                textRows.push(<div key="msg1">Download a list of items that failed due to import errors.</div>); 
                textRows.push(<div key="separator" className="statusTextSeparator" />);
                textRows.push(<div key="msg2">Review the Summary tab of the spreadsheet for failure reasons.</div>);
                textRows.push(<p key="anotherseparator"></p>);
                textRows.push(<div key="msg3"> Please fix the import errors and try importing again.</div>);
                break;
            case "Completed":
                imageSource = "https://files.connectwise.com/UI/Icons/v1.0/Status_Success.svg";
                textHeader = "Success! Your import is complete";
                textRows.push(<div key="msg1">Navigate to System and select Data Import to import more configurations.</div>);
                break;
            case "Pending":
            case "Processing":
            default:
                imageSource = "https://files.connectwise.com/UI/Icons/v1.0/Application_Time.svg";
                textHeader = "Almost there! Your import is in progress";
                textRows.push(<div key="msg1">You can now close this screen or keep it open to see when your import is complete.</div>);
                textRows.push(<div key="separator" className="statusTextSeparator" />);
                textRows.push(<div key="msg2">Navigate to System and select Data Import, then Jobs for import details.</div>);
        }

        let resultMessageDiv = <div>
            <div>
                <CwImage className="statusImage" style={{ "fill": "white", "height": "130px", "width": "180px" }} src={imageSource} />
            </div>
            <div className="statusHeader">
                {textHeader}
            </div>
            <div className="statusMessage">
                {textRows}
            </div>
        </div>;

        //If button is pressed, activate redirect to new page
        if (this.state.redirect === true) {
            return <div>
                <Redirect push to={this.state.redirectRoute} />
            </div>;
        }      
                
       
        return <div>
            <div className="pageTop">
                <div id="header">
                    <CwImage className="connectWiseLogo" src="logo-copy.svg" />
                </div>
                {this.state.displaySpinner ?
                <div>
                    <div className="statusMessage">
                        <CwLoader loaderCnt={1} />
                    </div> 
                </div> :
                    resultMessageDiv
                }
            </div>        
            <div className="pageBottom">
                {
                    this.state.displaySpinner === false ?
                    <div className="downloadButton" onClick={this.handleFirstPageReturn}>
                        <div className="downloadButtonText">Import Another File</div>
                    </div> :
                    null
                }    
                <div className="dividerBetweenDownloadButtons" />
                {
                    showDownloadLink ? 
                    <div className="downloadButton" onClick={this.handleSpreadsheetDownload}>
                            <div className="downloadButtonText">Download Error Details</div>
                    </div>:
                    null
                }
                 </div> 

        </div>;
    }
}

const mapStateToProps = state => {
    let id = state.setup.configTypeId;
    return {
        uniqueCompanies: state.mapping.uniqueCompanies,
        configTypeQuestions: state.setup.configTypeQuestionDictionary[id],
        file: state.setup.file,
        mapping: state.mapping.mappings,
        mappingHeaders: state.mapping.mappingHeaders,
        vendor: state.setup.vendor,
        startLine: state.mapping.startLine,
        renewable: state.setup.renewable,
        selectConfigType: state.setup.configType,
        appSettings: state.configs.appSettings,
        templateName: state.mapping.templateName,
        configTypeId: state.setup.configTypeId,
        duplicateConfigurations: state.duplicates.duplicateConfigurations,
        importWithDuplicates: state.duplicates.importWithDuplicates
    };
};

const mapDispatchToProps = {
    resetSetupScreen,
    resetMappingScreen
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Summary);

