import React, { Component } from 'react';
import './NavButtons.css';
import { Redirect, Prompt } from 'react-router';
import { CwButton, CwImage } from 'cw-widgets';

export class NavButtons extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            prompt: false,
            redirectRoute: ""
        };
        this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
        this.handleForwardButtonClick = this.handleForwardButtonClick.bind(this);
        this.reroute = this.reroute.bind(this);
    }

    handleBackButtonClick() {
        if (this.props.backCustom) {
            this.props.backCustom();
        }
        let route = this.props.backRoute ? this.props.backRoute : '/';
        this.reroute(route);
    }

    handleForwardButtonClick() {
        let route = this.props.forwardRoute ? this.props.forwardRoute : '/home';
        if (this.props.forwardCustom) {
            this.props.forwardCustom()
                .then(blockRoute => {
                    if (!blockRoute) {
                        this.reroute(route);
                    }
                });
        }
        else {
            this.reroute(route);
        }
    }

    reroute(route) {
        this.setState({ redirect: true, prompt: false, redirectRoute: route })
    }

    render() {
        let backProps = {};
        let forwardDisabled = !this.props.forwardLinkActive();

        //If back button is expected to be visible
        if (!this.props.backButtonDisabled) {
            backProps.onClick = this.handleBackButtonClick;
            backProps.className = "backButton"
            backProps.value = <CwImage className="navButtonImage" src="https://files.connectwise.com/UI/Icons/v1.0/Action_Back.svg" />;
        }

        let forwardProps = {};
        forwardProps.disabled = forwardDisabled;
        forwardProps.onClick = this.handleForwardButtonClick;
        forwardProps.className = forwardDisabled ? "forwardButtonDisabled" : "forwardButton";

        forwardProps.value = <CwImage className="navButtonImage" src="https://files.connectwise.com/UI/Icons/v1.0/Action_Back.svg" />;

        let backButton = <CwButton {...backProps} />;
        let forwardButton = <CwButton {...forwardProps} />; 

        if (this.props.finalButton) {
            let finalProps = {};
            let imageState = forwardDisabled ? "finalNavButtonImageDisabled" : "finalNavButtonImage";
            finalProps.disabled = forwardDisabled;
            finalProps.onClick = this.handleForwardButtonClick;
            finalProps.className = forwardDisabled ? "finalButtonDisabled" : "finalButton";

            finalProps.value = <CwImage className={imageState} src="https://files.connectwise.com/UI/Icons/v1.0/Action_Download.svg" />;

            let finalText = this.props.forwardTooltipText;
            let finalTooltip = <span className="finalButtonTooltip">{finalText}</span>;
            forwardButton = <span><CwButton {...finalProps} />{ finalText ? finalTooltip : null }</span >
        }

        //If button is pressed, activate redirect to new page
        if (this.state.redirect === true) {
            return <div>
                <Redirect push to={this.state.redirectRoute} />
                <Prompt when={this.state.prompt} message='If you leave, unsaved changes will be lost. Continue?' />
            </div>;
        }

        if (this.props.disableBackButton) {
            return <div>
                {forwardButton}
            </div>
        }
        return <div>
            {backButton}{forwardButton}
        </div>;
    }
}