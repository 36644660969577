import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

let connectWiseClientApi;
let timer;

document.addEventListener("DOMContentLoaded", function (event) {
    onReady();
});

function onReady() {
    timer = setTimeout(DisplayError, 60000, "Authentication request to Manage timed out."); // "Authentication request to Manage Hosted API timed out."
    let targetWindow = window.parent;
    if (targetWindow) {
        let message = JSON.stringify({ 'message': 'wizardloaded' });
        window.addEventListener("message", receiveMessage, false);
        targetWindow.postMessage(message, "*");
    }
    else {
        // Old code to work with HostedAPI authentication
        connectWiseClientApi = new window.ConnectWiseHostedAPI('*',
            {
                "eventHandlers": [
                    { "event": "onLoad", "callback": onLoadCallback }
                ]
            }
            , true);   
    }
};

function receiveMessage(event) {
    // Do we trust the sender of this message?
    if (event.origin.indexOf("localhost") === -1 && event.origin.indexOf("connectwisedev.com") === -1 &&
        event.origin.indexOf("myconnectwise.net") === -1 && event.origin.indexOf("cw.connectwise.net") === -1) {
        return;
    }

    if (event.data) {
        let data = JSON.parse(event.data);
        if (data.memberHash) {
            window.removeEventListener("message", receiveMessage);
            handleAuthData(data);
        }
    }
  }

function handleAuthData(authData) {
    clearTimeout(timer);
    const manageInfo = {
        'memberHash': authData.memberHash,
        'memberId': authData.memberid ? authData.memberid : authData.memberId,
        'companyId': authData.companyid ? authData.companyid : authData.companyId,
        'site': authData.site,
        'version': authData.codeBase,
        'memberEmail': authData.memberEmail,
        'memberContext': authData.memberContext
    }
    ReactDOM.render(
        <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
                <BrowserRouter basename={baseUrl}>
                    <App id="info" manageInfo={manageInfo} />
                </BrowserRouter>
            </DndProvider>
        </Provider>,
        rootElement);
}

async function onLoadCallback() {
    connectWiseClientApi.post({ "request": "getMemberAuthentication" },
        async function (authData) {
            clearTimeout(timer);
            if (authData && authData.memberHash) {
                handleAuthData(authData);
            }
            else {
                let errorText = "Member hash was not present for attempted access";
                DisplayError(errorText);
            }            
        })
};

function DisplayError(errorText)
{
    let error = {};
    error["errorText"] = errorText;
    fetch('api/cwRestApi/logFrontEndError/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(error)
    });
    ReactDOM.render(
        <h1> Sorry we are unable to load this page. The error has been logged. Please try again later. </h1>,
        rootElement);
}

//display some message like "loading, please wait?
ReactDOM.render(
    <h1> Loading Your Page </h1>,
    rootElement);

///////////////////////////////
registerServiceWorker();


