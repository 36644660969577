import React from 'react';
import './Page.css';
import { CwImage } from 'cw-widgets';

export class SearchControl extends React.Component {
    constructor(props) {
        super(props);

        this.onKeyPress = this.onKeyPress.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onKeyPress(event) {
        if (event.keyCode === 13 || event.which === 13) {
            this.onSearch();
        }
    }
    onSearch() {
        this.props.onSearch(this.props.returnParams, this.props.searchValue);
    }
    onChange(e) {
        this.props.changeSearchValue(this.props.spreadsheetName, e.target.value)
    }
    render() {
        return <div className="rectangle">
            <div className="linkedCompany">
                <table id={this.props.id} width="100%">
                    <tbody>
                        <tr key={"searchControlRow" + this.props.id}>
                            <td key={this.props.id + "TextTd"} id={this.props.id + "Text"}>
                                <input
                                    id={this.props.id + "Input"}
                                    type="text"
                                    className="noBorder"
                                    onKeyPress={this.onKeyPress}
                                    onChange={this.onChange}
                                    value={this.props.searchValue}
                                    placeholder="Link Company"></input>
                            </td>
                            <td key={this.props.id + "SearchTd"} id={this.props.id + "Search"} onClick={this.onSearch} style={{ "verticalAlign": "middle" }} >
                                <CwImage src="https://files.connectwise.com/UI/Icons/v1.0/Action_Search.svg" className="clear" style={{ "verticalAlign": "middle", "padding": "5px", "cursor": "pointer"}} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
}