import {
    SET_CONFIGS
} from "../actionTypes";

const initialState = {
    appSettings: null,
    configsLoaded: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CONFIGS: {
            return Object.assign({}, state, {
                appSettings: action.payload.configs,
                configsLoaded: true
            })
        }
        default: {
            return state;
        }
    }
};        