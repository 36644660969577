import React from 'react';
import './Popup.css';
import { CwButton, CwDialog } from 'cw-widgets';


class Popup extends React.Component {
    render() {
        const dialogButtons = () => {
            return [<CwButton isStandard key="dialogOk" className="okButton" onClick={this.props.closePopup} value={'OK'}></CwButton>];
        }
        return (
            <CwDialog
                header={this.props.title}
                buttons={dialogButtons()}
                width={400}
            >
                <h2>{this.props.text}</h2>
            </CwDialog>
        );
    }
}
export default Popup;