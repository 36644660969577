import React, { Component } from 'react';
import { SplitFrameScrollPadding } from '../Shared/SplitFrameScrollPadding';
import { CwLabel, CwCheckBox, CwButton, CwDialog } from 'cw-widgets';
import { Redirect } from 'react-router';
import './Mapping.css';
import { PageWrapper } from '../Shared/PageWrapper';

export class DuplicateConfigsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
        this.buildSpreadsheetPreview = this.buildSpreadsheetPreview.bind(this);
        this.getStartIndex = this.getStartIndex.bind(this);
        this.getPage = this.getPage.bind(this);
        this.continue = this.continue.bind(this);
    }

    getStartIndex(pageSize, pageNumber) {
        return (pageSize * (pageNumber - 1));
    }

    buildSpreadsheetPreview(headers, dataArray) {
        let numOfColumns = headers ? headers.length : 0;
        let numOfRows = dataArray ? dataArray.length : 0;
        //create header columns
        let headerColumns = [];
        for (let col = 0; col <= numOfColumns; col++) {
            if (col === 0) {
                headerColumns.push(<th key={'headerRowIndex'} width="25px"><div /></th>);
            }
            else {
                headerColumns.push(<th key={'header' + headers[col - 1].name} className="dataTableHeader" width="30%"><div className="spreadsheetHeader"><CwLabel className="spreadsheetHeaderText">{headers[col - 1].displayName}</CwLabel></div></th>);
            }
        }

        //create rows
        let rows = [];
        //iterate over row quantity
        for (let row = 0; row < numOfRows; row++) {
            let rowData = [];
            //...and then iterate over column quantity
            for (let col = 0; col <= numOfColumns; col++) {
                if (col === 0) {
                    rowData.push(<td className="dataCellWithRowCount" key={'cell_col_' + col + '_row_' + row}></td>);
                }
                else {
                    let cellData = <div className="dataCellInner">{dataArray[row][headers[col - 1].name]}</div> || null;
                    rowData.push(<td className="dataCell" key={'cell_col_' + col + '_row_' + row}>{cellData}</td>);
                }
            }
            rows.push(<tr key={'datatr' + row} className="normalDataRow">{rowData}</tr>);
        }

        //return final table object
        return <table id="dataTableInner" width="100%">
            <thead>
                <tr>
                    {headerColumns}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
                    </table>;
    }

    continue() {
        this.setState({redirect: true})
    }

    getPage(pageDetails, pageSize) {
        let startIndex = this.getStartIndex(pageSize, pageDetails.currentPage);
        let endIndex = startIndex + pageSize;
        let pageNames = this.props.tableData.slice(startIndex, endIndex);

        let cancelFrame = <div className="cancelFrame"><div className="cancelFrameSquare"></div></div>
        let dataTable = this.buildSpreadsheetPreview(this.props.tableHeaders, pageNames);
        let spreadsheetPreviewFrame = <div id="tableOverflowWrapper" className="tableStyle">
            {dataTable}
        </div>

        let table = <div id="dataTableContainerOuter">
            <SplitFrameScrollPadding
                tableId="duplicatesTableFrame"
                leftId="duplicatesCancelFrame"
                rightId="duplicatesViewFrame"
                leftFrameWidth="40px"
                rightFrameWidth="Auto"
                frameHeight="100%"
                frameWidth="300px"
                leftChildren={cancelFrame}
                rightChildren={spreadsheetPreviewFrame}
            />
        </div>;

        return table;
    }

    render() {        
        let pageSize = 4;
        let dataSize = this.props.tableData ? this.props.tableData.length : 0;

        if (this.state.redirect === true) {
            return <div>
                <Redirect push to={this.props.redirectRoute} />
            </div>;
        }

        return (
            <div>
                <div className="disableBg">
                </div>
                <CwDialog
                    header={"Duplicates Found"}
                    width={800}
                    getDialogPosition={this.props.getDialogPosition}
                >
                    <div id={this.props.dialogId} >
                        <div className="header-summary">The potential duplicate configurations this import will create are shown below. Please indicate if you want to Import With Duplicates to create new configurations for each line listed, or Import Without Duplicates to skip the lines shown and avoid creating duplicates when importing.</div>
                    </div>                
                    <PageWrapper 
                        render={pageDetails => (
                            this.getPage(pageDetails, pageSize)
                        )}
                        dataSize={dataSize}
                        pageSize={pageSize}
                    >
                    <CwLabel></CwLabel>
                    </PageWrapper>                
                    <div>
                        <table id="duplicatesActionsTable" width="100%">
                            <tbody>
                                <tr key="rowLast">
                                    <td id="duplicatesActionImport" className="twoColumnLayout">
                                        <div className="setupscreenlayout renewalsCheckbox">
                                            <CwCheckBox
                                                name="importWithDupsCheckbox"
                                                text="Import with duplicates"
                                                value={this.props.importWithDuplicates}
                                                onChange={this.props.toggleImportWithDuplicates}
                                                style={{ "font": "14px" }}
                                            />
                                        </div>
                                    </td>
                                    <td id="duplicatesActionButtons"  className="twoColumnLayout">
                                        <div>
                                            <div> <CwButton isStandard key="dialogOk" style={{ "float":"right"}} className="okButton" onClick={this.continue} value={'APPLY'}></CwButton></div>
                                            <div><CwButton  key="dialogCancel" style={{ "float": "left" }} className="okButton" onClick={this.props.cancelDuplicates} value={'CANCEL'}></CwButton></div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </CwDialog>
            </div>
        )
    };
}
