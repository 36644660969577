import React, { Component } from 'react';
export class Home extends Component {
    displayName = Home.name


    render() {
        return (
            <div>
                <h1>Hello, world!</h1>
            </div>
        );
    }
}
