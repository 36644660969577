import React, { Component } from 'react';
import { SplitFrameScrollPadding } from '../Shared/SplitFrameScrollPadding';
import { CwLabel, CwImage } from 'cw-widgets';
import { Recommended } from '../Shared/Recommended';
import { NumberInput } from '../Shared/NumberInput';
import './Mapping.css';

export class Mapping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            skipHeaders: false,
            firstLineIndex: 0,
            draggedItemId: null,
            mappingHeaders: {}
        };
        this.startLineValues = [];
        this.buildSpreadsheetPreview = this.buildSpreadsheetPreview.bind(this);

        this.onDragStartHandler = this.onDragStartHandler.bind(this);
        this.onDropHandler = this.onDropHandler.bind(this);

        this.removeMapping = this.removeMapping.bind(this);
    }

    buildMappingHeadersFromMappings(mappingDictionary, recommendedRows, additionalRows) {
        let mappingHeaderDictionary = {};
        for (let mapping in mappingDictionary) {

            let mappingLabel = recommendedRows[mapping] || additionalRows[mapping];
            if (!mappingLabel) {
                continue;
            }
            mappingLabel = this.truncateHeaderLabel(mappingLabel);
            let mappingValue = mappingDictionary[mapping];
            let dictionaryProperty = mappingHeaderDictionary[mappingValue];
            if (dictionaryProperty === undefined || dictionaryProperty === null) {
                dictionaryProperty = [];
            }
            dictionaryProperty.push({ id: mapping, label: mappingLabel });
            mappingHeaderDictionary[mappingValue] = dictionaryProperty;
        }
        return mappingHeaderDictionary;
    }

    checkMappedHeaders(mappings, rows) {
        let returnRows = [];
        for (let row in rows) {
            if (!mappings[row]) {
                returnRows[row] = rows[row];
            }
        }
        return returnRows;
    }

    onDragStartHandler(ev, id) {
        ev.preventDefault();
        this.setState({ draggedItemId: id });
        document.getElementById("mappingframe").style.cursor = "move"; 
    }

    truncateHeaderLabel(label) {
        if (label.length > 17) {
            label = label.substring(0, 17);
            label = label + "...";
            return label;
        }
        else {
            return label;
        }
    }

    onDropHandler(ev, colid) {
        ev.preventDefault();
        document.getElementById("mappingframe").style.cursor = "auto"; 

        // Get the id of the target and add the moved element to the target's DOM
        let rowid = this.state["draggedItemId"];
        this.setState({ draggedItemId: null });
        if (colid === undefined || rowid === undefined || rowid === null || rowid === "") {
            return;
        }
       
        let dict = this.props.mapping;
        dict[rowid] = colid;
        let mappingDetail = { colid: rowid, mappings: dict[rowid] }
        this.props.setMapping(mappingDetail);
    }
    removeMapping(colid, propid) {
        this.props.removeMapping(propid);
    }

    buildSpreadsheetPreview(mappingHeaders) {

        function countforeach(value) {
            let length = value.length;
            if (length > maxLength) {
                maxLength = length;
            }
        }
        let dataArray = this.props.dataPreview;
        let maxLength = 0;
        dataArray.forEach(countforeach);
        this.startLineValues = [];
        //create header columns
        let headerColumns = [];
        let removeMapping = this.removeMapping;
        let dropHandler = this.onDropHandler;
        for (let i = 0; i < maxLength; i++) {
            let id = "col" + i;
            let headerRows = [];
            if (mappingHeaders && mappingHeaders[id]) {
                mappingHeaders[id].forEach(item => {
                    let propId = "";
                    propId = item.id;
                    let label = item.label;
                    headerRows.push(<tr key={'headerrow' + id + '_' + propId}><td><CwLabel className="spreadsheetHeaderText">{label}</CwLabel></td><td><CwImage className="clear" src="https://files.connectwise.com/UI/Icons/v1.0/Action_Close.svg" onClick={() => removeMapping(id, propId)} /></td></tr>);
                });
            }
            if (i === 0) {
                headerColumns.push(<th key={'header' + id} className="dataTableHeader"><div className="spreadsheetHeaderNumberOffset"></div><div className="spreadsheetHeader" onMouseUp={(ev) => dropHandler(ev, id)}><table><tbody id={id} >
                    {headerRows}
                </tbody></table></div></th>);
            }
            else {
                headerColumns.push(<th key={'header' + id} className="dataTableHeader"><div className="spreadsheetHeader" onMouseUp={(ev) => dropHandler(ev, id)}><table><tbody id={id} >
                    {headerRows}
                </tbody></table></div></th>);
            }
        }

        //Make sure start line can't be set to greater than number of import rows

        //create rows
        let rows = [];
        //iterate over row quantity
        for (let row = 0; row < dataArray.length; row++) {
            let idName = (row + 1).toString();
            this.startLineValues.push({ id: row, name: idName })
            let rowColumns = [];
            //...and then iterate over column quantity
            for (let col = 0; col < maxLength; col++) {


                let cellData = <div className="dataCellInner">{dataArray[row][col]}</div> || null;
                let lineNumber = <div className="numberCell">{row+1}</div>
                if (col === 0) {
                    rowColumns.push(<td className="dataCellWithRowCount" key={'cell_col_' + col + '_row_' + row}>{lineNumber}{cellData}</td>);
                }
                else {
                    rowColumns.push(<td className="dataCell" key={'cell_col_' + col + '_row_' + row}>{cellData}</td>);
                }

            }
            let rowState = "normalDataRow";
            if (row < this.props.startLine) {
                rowState = "skippedDataRow";
            }
            rows.push(<tr key={'datatr' + row} className={rowState}>{rowColumns}</tr>);
        }

        //return final table object
        return <table id="dataTableInner">
            <thead>
                <tr>
                    {headerColumns}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
                    </table>;
    }
    render() {
        let mappingHeaders = this.buildMappingHeadersFromMappings(this.props.mapping, this.props.recommended, this.props.additionalFields);

        let recommendedRows = this.checkMappedHeaders(this.props.mapping, this.props.recommended);
        let additionalRows = this.checkMappedHeaders(this.props.mapping, this.props.additionalFields);

        let leftFrame = <div>
            <div className="recommendedOuter">
                <Recommended
                    title="Recommended"
                    values={recommendedRows}
                    dragStartHandler={this.onDragStartHandler}
                    mapping={this.props.mapping} />
            </div>
            <div height="20px"></div>
            <Recommended
                title="Additional Fields"
                values={additionalRows}
                dragStartHandler={this.onDragStartHandler}
                mapping={this.props.mapping}
                id="recommendedSection" />
        </div>;

        let cancelFrame = <div className="cancelFrame"><div className="cancelFrameSquare"></div></div>

        let dataTable = this.buildSpreadsheetPreview(mappingHeaders);

        let spreadsheetPreviewFrame = <div id="tableOverflowWrapper" className="tableStyle">
            {dataTable}
        </div>

        //Display data preview table and columns for mappings here
        let rightFrame = <div id="dataTableContainerOuter">
            <SplitFrameScrollPadding
                tableId="mappingTableFrame"
                leftId="mappingCancelFrame"
                rightId="mappingSpreadsheetFrame"
                leftFrameWidth="40px"
                rightFrameWidth="Auto"
                frameHeight="100%"
                frameWidth="300px"
                leftChildren={cancelFrame}
                rightChildren={spreadsheetPreviewFrame}
            />
        </div>;

        return (
            <div>
                <div id="header">
                    <NumberInput setStartLine={this.props.setStartLine} selectableItems={this.startLineValues} startLine={this.props.startLine} />
                </div>
            <div id="pagetable">
                <SplitFrameScrollPadding id="mappingframe"
                    tableId="mappingframe"
                    leftId="leftmappingframe"
                    rightId="rightmappingframe"
                    leftFrameWidth="260px"
                    rightFrameWidth="Auto"
                    frameHeight="580px"
                    frameWidth="600px"
                    leftChildren={leftFrame}
                    rightChildren={rightFrame}
                />
            </div>
        </div>

        )
    };
}
