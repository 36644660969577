import React from 'react';
import './Page.css';
import {CwComboBox} from 'cw-widgets';

export class NumberInput extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }
    onChange(e) {
        this.props.setStartLine(e.id);
    }
    render() {
        return <div>
            <table className="startLineControlContainer">
                <tbody>
                <tr>
                        <td>
                        <label className="previewLabel">Select which line you would like your import to start on:</label>
     
                    </td>
                    <td>
                        <CwComboBox
                            onChange={this.onChange}
                            items={this.props.selectableItems}
                            clickAreaToExpand
                            value={this.props.selectableItems[this.props.startLine]}
                            disabled={this.props.loading}
                            id='numberSelectionBox'
                        />
            </td>
                    </tr>
                    </tbody>
                    </table>
            </div>;
    }
}