import React, { Component } from 'react';
import { CwDataGrid } from 'cw-widgets';
import { SearchControl } from '../Shared/SearchControl';

//props:
//searchCompanies: function to check new search value
//selectCompany: function to set selected company and close dialog
//initialSearchResults: array of data pulled from first data call from company linking screen
export class CompanySearchDialog extends Component {
    constructor(props) {
        super(props);

        let defaultColumnProperties = {
            width: 150,
            sortable: true,
            triggerIcon:'',
        }

        //Define columns that will be used by data table to display search results
        this.columnDefs = [
            {
                key: 'col1',
                label: 'Company',
                cellType: 'link',
                onClick: this.props.selectCompany,
                ...defaultColumnProperties, 
                width: 250
            },
            {
                key: 'col2',
                label: 'Lead Status',
                cellType: 'text',
                ...defaultColumnProperties
            },
            {
                key: 'col3',
                label: 'Territory',
                cellType: 'text',
                ...defaultColumnProperties
            },
            {
                key: 'col4',
                label: 'Type',
                cellType: 'text',
                ...defaultColumnProperties
            },
            {
                key: 'col5',
                label: 'Market',
                cellType: 'text',
                ...defaultColumnProperties
            },
            {
                key: 'col6',
                label: 'Status',
                cellType: 'text',
                ...defaultColumnProperties
            },
            {
                key: 'col7',
                label: 'Entered',
                cellType: 'date',
                ...defaultColumnProperties
            },
            {
                key: 'col8',
                label: 'Site',
                cellType: 'text',
                ...defaultColumnProperties
            },
            {
                key: 'col9',
                label: 'Address',
                cellType: 'text',
                ...defaultColumnProperties
            },
            {
                key: 'col10',
                label: 'City',
                cellType: 'text',
                ...defaultColumnProperties
            },
            {
                key: 'col11',
                label: 'State',
                cellType: 'text',
                ...defaultColumnProperties
            },
            {
                key: 'col12',
                label: 'Zip Code',
                cellType: 'text',
                ...defaultColumnProperties
            },
        ]

        this.handleColumnFilterChange = this.handleColumnFilterChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    handleColumnFilterChange(col, value) {
        this.setState({
            filterValues: {
                ...this.state.filterValues,
                [col.key]: value,
            },
        });
    }

    handleSearch(companyId) {
        this.props.handleSearch(companyId, this.props.searchDialogValue);
    }

    render() {
        let id = "companyLinkDialog" + this.props.searchDialogId;

        return <div>
            <SearchControl
                spreadsheetName={this.props.searchDialogId}
                id={id}
                onSearch={this.handleSearch}
                text={this.props.searchDialogValue}
                returnParams={this.props.searchDialogId}
                searchValue={this.props.searchDialogValue}
                changeSearchValue={this.props.setTemporarySearchDetails}
            />
            <CwDataGrid
                height={400}
                columnDefs={this.columnDefs}
                data={this.props.searchDialogResults}
                onColumnFilterChange={this.handleColumnFilterChange}
                noResultsText={"Nothing Found"}
                useAltColors
            />
        </div>;
    }
}