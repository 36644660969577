import { Component } from 'react';
import { connect } from "react-redux";
import { setConfigs } from "../redux/actions";

export class ConfigContainer extends Component {
    constructor(props) {
        super(props);

        this.componentDidMount = this.componentDidMount.bind(this);
        this.fetchBackendConfigs = this.fetchBackendConfigs.bind(this);
    }

    componentDidMount() {
        //Call fetch and perform any additional necessary data manipulation here
        this.fetchBackendConfigs()
            .then(newConfigs => {
                this.props.setInstrumentationKey(newConfigs.instrumentationKey);
                this.props.setConfigs(newConfigs);
            })
            .then(result => {
                this.props.appSettingsLoaded();
            })
    }

    fetchBackendConfigs() {
        var displayError = this.props.displayError;
        return fetch('api/Configurations', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => this.props.handleFetchErrors(response))
            .catch(function (error) {
                console.log(error);
                displayError(error.message, true);
            });
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = {
    setConfigs
};

export default connect(
    null,
    mapDispatchToProps
)(ConfigContainer);