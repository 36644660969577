import React, { Component } from 'react';
import { CwComboBox, CwLabel } from 'cw-widgets';
import './Page.css';

export class Dropdown extends Component {

    render() {
        let id = this.props.id;
        let optionList = [];
        let selectedOption = this.props.selection
        if (this.props.options) {
            this.props.options.map((option) => optionList.push({ id: option.id, name: option.name }));
        }
        return <div id={id + "Div"} className={"dropdownDiv"}>
            {this.props.header ?
                <div className={"formLabel"}>
                    <CwLabel>{this.props.header}</CwLabel>
                </div>
                :null}
            <div>
                <CwComboBox
                    placeholder={this.props.placeholderText}
                    onChange={this.props.handleOptionChange}
                    items={optionList}
                    clickAreaToExpand
                    cwId={id}
                    value={this.props.loading ? this.props.loadingText : selectedOption}
                    disabled={this.props.loading}
                />
            </div>
        </div>;
    }
}