import {
    SELECT_CONFIGURATION_TYPE,
    SELECT_VENDOR,
    SELECT_FILE,
    ADD_DATA_PREVIEW,
    ADD_CONFIGURATION_TYPE_QUESTIONS,
    ADD_CONFIGURATION_TYPES,
    ADD_VENDORS,
    TOGGLE_RENEWABLE,
    SET_MAPPING,
    REMOVE_MAPPING,
    LOAD_MAPPINGS,
    ADD_UNIQUE_COMPANIES,
    SET_COMPANY_AND_SITE_LINK,
    SET_SITE_LINK,
    RESET_MAPPING_SCREEN,
    RESET_COMPANY_LINKING_SCREEN,
    SET_START_LINE,
    SET_CONFIGS,
    RESET_SETUP_SCREEN,
    SELECT_MAPPING_TEMPLATE,
    SET_DUPES,
    SET_IMPORT_WITH_DUPLICATES
} from './actionTypes';

export const selectConfigType = configType => ({
    type: SELECT_CONFIGURATION_TYPE,
    payload: { configType }
});

export const setStartLine = startLine => ({
    type: SET_START_LINE,
    payload: { startLine }
});

export const selectVendor = vendor => ({
    type: SELECT_VENDOR,
    payload: { vendor }
});

export const selectFile = file => ({
    type: SELECT_FILE,
    payload: { file }
})

export const addDataPreview = dataPreview => ({
    type: ADD_DATA_PREVIEW,
    payload: { dataPreview }
})

export const addConfigTypeQuestions = configTypeQuestions => ({
    type: ADD_CONFIGURATION_TYPE_QUESTIONS,
    payload: { configTypeQuestions }
})

export const addConfigTypes = configTypes => ({
    type: ADD_CONFIGURATION_TYPES,
    payload: { configTypes }
});

export const addVendors = vendors => ({
    type: ADD_VENDORS,
    payload: { vendors }
});

export const toggleRenewable = renewable => ({
    type: TOGGLE_RENEWABLE,
    payload: { renewable }
})


export const setMapping = mapping => ({
    type: SET_MAPPING,
    payload: { mapping }
})

export const removeMapping = mappingId => ({
    type: REMOVE_MAPPING,
    payload: { mappingId }
})

export const loadMappings = mappings => ({
    type: LOAD_MAPPINGS,
    payload: { mappings }
})

export const addUniqueCompanies = companies => ({
    type: ADD_UNIQUE_COMPANIES,
    payload: { companies }
})

export const setCompanyAndSiteLink = company => ({
    type: SET_COMPANY_AND_SITE_LINK,
    payload: { company }
})

export const setSiteLink = company => ({
    type: SET_SITE_LINK,
    payload: { company }
})

export const resetMappingScreen = reset => ({
    type: RESET_MAPPING_SCREEN,
    payload: { reset }
})

export const resetCompanyLinkingScreen = reset => ({
    type: RESET_COMPANY_LINKING_SCREEN,
    payload: { reset }
})

export const setConfigs = configs => ({
    type: SET_CONFIGS,
    payload: { configs }
})

export const resetSetupScreen = reset => ({
    type: RESET_SETUP_SCREEN,
    payload: { reset }
})

export const selectMappingTemplate = template => ({
    type: SELECT_MAPPING_TEMPLATE,
    payload: { template }
})

export const setDupes = dupeList => ({
    type: SET_DUPES,
    payload: { dupeList }
})

export const setImportWithDuplicates = importWithDuplicates => ({
    type: SET_IMPORT_WITH_DUPLICATES,
    payload: { importWithDuplicates }
})