import React, { Component } from 'react';
import { CwImage } from 'cw-widgets';
import './Page.css';

export class PageLogoHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return <div>
            <div className="pageHeader">
                <div className="headerLogo">
                    <CwImage className="connectWiseLogo" src="logo-copy.svg" />
                </div>
                <div className="headerPageCounter">
                    <div>{this.props.pageNumber} of 3 Steps</div>
                </div>
            </div>
        </div>;
    }
}