import React, { Component } from 'react';
import { CwButton } from 'cw-widgets';
import './PageWrapper.css';

export class PageWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        };

        this.setCurrentPage = this.setCurrentPage.bind(this);
    }

    calculatePageCount(dataSize, pageSize) {
        return Math.ceil(dataSize / pageSize);
    }

    setCurrentPage(newPageNumber) {
        this.setState({
            currentPage: newPageNumber
        })
    }

    //Choose whether to show or hide a page. return value is True if page number is displayed and False if page number is hidden
    showOrHidePageNumber(page, currentPage, pageCount) {
        let distanceFromPageToCurrentPage = Math.abs(currentPage - page);
        let distanceFromPageToFirstPage = Math.abs(page - 1);
        let distanceFromPageToLastPage = Math.abs(pageCount - page);

        let showPage = distanceFromPageToCurrentPage <= 1 || distanceFromPageToFirstPage <= 1 || distanceFromPageToLastPage <= 1;
        return showPage;
    }

    render() {
        let pageCount = this.calculatePageCount(this.props.dataSize, this.props.pageSize);

        let pageButtons = [];

        let currentPage = this.state.currentPage
        let backButton = <CwButton
            key="back"
            onClick={this.setCurrentPage.bind(this, currentPage - 1)}
            icon="https://files.connectwise.com/UI/Icons/v1.0/Action_Back.svg"
            disabled={currentPage === 1}
            className="pageButton pageBackButton"
        />

        pageButtons.push(backButton);

        //First
        let ellipsesCheck = true;

        for (let i = 1; i <= pageCount; i++) {
            //If page should be shown, create element
            if (this.showOrHidePageNumber(i, this.state.currentPage, pageCount)) {
                let buttonClassName = "pageButton";
                if (this.state.currentPage === i) {
                    buttonClassName = "pageButton pageButtonSelected";
                }

                let button = <CwButton
                    key={i}
                    className={buttonClassName}
                    onClick={this.setCurrentPage.bind(this, i)}
                    value={i}
                />;
                pageButtons.push(button);
                ellipsesCheck = true;
            }
            //If not, check if ellipses should be shown
            else {
                if (ellipsesCheck) {
                    let gap = <CwButton 
                        key={"ellipses" + i}
                        className="pageGap"
                        value={"..."}
                        disabled
                    />
                    ellipsesCheck = false;
                    pageButtons.push(gap);
                }
            }
        }

        let forwardButton = <CwButton
            key="forward"
            onClick={this.setCurrentPage.bind(this, currentPage + 1)}
            icon="https://files.connectwise.com/UI/Icons/v1.0/Action_Back.svg"
            disabled={currentPage === pageCount}
            className="pageButton pageForwardButton"
        />

        pageButtons.push(forwardButton);

        return <div>
            <div className="pageBar">
                {this.props.children}
                <span className="pageButtons">
                    {pageButtons}
                </span>
            </div>
            <div className="pageContent">
                {this.props.render(this.state)}
            </div>
        </div>;
    }
}