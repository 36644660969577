import React, { Component } from 'react';
import { Route } from 'react-router';
import { Home } from './components/Home';
import Setup from './components/Pages/Setup';
import Popup from './components/Popup';
import CompanyLinking from './components/Pages/CompanyLinking';
import Summary from './components/Pages/Summary';
import ConfigContainer from './components/ConfigContainer';
import MappingsPage from './components/Pages/MappingsPage';


import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import initiateAppInsightsWithReactPlugin from "./components/Shared/AppInsights";

var reactPlugin = new ReactPlugin();

class App extends Component {
    displayName = App.name;

    constructor(props) {
        super(props)

        this.state = {
            showPopup: false,
            messageTitle: "",
            messageText: "",
            settingsLoaded: false,
            instrumentationKey: ""
        }
        this.togglePopup = this.togglePopup.bind(this);
        this.popupMessage = this.popupMessage.bind(this);
        this.displayError = this.displayError.bind(this);
        this.getManageInfoHeader = this.getManageInfoHeader.bind(this);
        this.appSettingsLoaded = this.appSettingsLoaded.bind(this);
        this.setInstrumentationKey = this.setInstrumentationKey.bind(this);
    }

    handleFetchErrors(response) {
        if (response.isError) {
            throw Error(response.message);
        }
        return response;
    }

    displayError(errorMessage, isApiError) {
        var message = isApiError ? 'Sorry we are unable to load this page. The error has been logged. Please try again later.' : '';
        if (errorMessage) {
            message += ' The Error was: ' + errorMessage;
        }
        this.popupMessage('Error', message);
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

    appSettingsLoaded() {
        this.setState({
            settingsLoaded: true
        });
    }

    setInstrumentationKey(key) {
        this.setState({
            instrumentationKey: key
        });
    }

    popupMessage(title, message) {
        this.setState({
            messageTitle: title,
            messageText: message,
            showPopup: true
        });
    }

    getManageInfoHeader(manageInfo) {
        let headers = new Headers();
        let basicAuth = Buffer.from(manageInfo.site + "+" + manageInfo.companyId + "+" + manageInfo.memberId + "+" + manageInfo.memberContext + ":" + manageInfo.memberHash).toString("base64");
        headers.append('Authorization', 'Basic ' + basicAuth);
        headers.append('Content-Type', 'application/json');
        return headers;
    }

    render() {
        var manageInfo = this.props.manageInfo;

        if (!this.state.settingsLoaded) {
            return <div>
                <ConfigContainer
                    handleFetchErrors={this.handleFetchErrors}
                    displayError={this.displayError}
                    appSettingsLoaded={this.appSettingsLoaded}
                    setInstrumentationKey={this.setInstrumentationKey}
                />
            </div>
        }
        else {
            initiateAppInsightsWithReactPlugin(reactPlugin, this.state.instrumentationKey);
            return (
                <div>
                    <Route path='/home' component={Home} />
                    <Route exact path='/' render={() =>
                        <Setup
                            manageInfo={manageInfo}
                            handleFetchErrors={this.handleFetchErrors}
                            displayError={this.displayError}
                        />}
                    />
                    <Route path='/setup' render={() =>
                        <Setup
                            manageInfo={manageInfo}
                            handleFetchErrors={this.handleFetchErrors}
                            displayError={this.displayError}
                        />}
                    />
                    <Route path='/mappingspage' render={() =>
                        <MappingsPage
                            manageInfo={manageInfo}
                            handleFetchErrors={this.handleFetchErrors}
                            displayError={this.displayError}
                            getManageInfoHeader={this.getManageInfoHeader}
                        />}
                    />
                    <Route path='/companylinking' render={() =>
                        <CompanyLinking
                            manageInfo={manageInfo}
                            handleFetchErrors={this.handleFetchErrors}
                            displayError={this.displayError}
                            getManageInfoHeader={this.getManageInfoHeader}
                        />}
                    />
                    <Route path='/summary' render={() =>
                        <Summary
                            manageInfo={manageInfo}
                            handleFetchErrors={this.handleFetchErrors}
                            displayError={this.displayError}
                            getManageInfoHeader={this.getManageInfoHeader}
                        />}
                    />
                    {this.state.showPopup ?
                        <Popup
                            title={this.state.messageTitle}
                            text={this.state.messageText}
                            closePopup={this.togglePopup}
                        />
                        : null
                    }
                </div>
            );
        }
    }
}
export default withAITracking(reactPlugin, App);