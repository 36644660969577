import {
    SET_MAPPING,
    REMOVE_MAPPING,
    LOAD_MAPPINGS,
    ADD_UNIQUE_COMPANIES,
    SET_COMPANY_AND_SITE_LINK,
    SET_SITE_LINK,
    RESET_MAPPING_SCREEN,
    RESET_COMPANY_LINKING_SCREEN,
    SET_START_LINE,
    SELECT_MAPPING_TEMPLATE
} from "../actionTypes";

const initialState = {
    mappings: {},
    startLine: 0,
    uniqueCompanies: {},
    templateName: ""
}

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_MAPPING: {
            let id = action.payload.mapping.colid;
            let mapping = action.payload.mapping.mappings;
            let newState = {
                ...state,
                mappings: {
                    ...state.mappings,
                    [id]: mapping
                }
            }
            return newState;
        }
        case REMOVE_MAPPING: {
            let currentState = state;
            let mappingProperty = "mappings";
            let id = action.payload.mappingId;

            let { [mappingProperty]: mappingValue, ...noMapping } = currentState;
            let { [id]: idValue, ...noId } = mappingValue;
            let newState = { ...noMapping, [mappingProperty]: noId }

            return newState;
        }
        case LOAD_MAPPINGS: {
            return Object.assign({}, state, {
                mappings: action.payload.mappings
            })
        }
        case SET_START_LINE: {
            return Object.assign({}, state, {
                startLine: action.payload.startLine
            })
        }
        case ADD_UNIQUE_COMPANIES: {
            return Object.assign({}, state, {
                uniqueCompanies: action.payload.companies
            })
        }
        case SET_COMPANY_AND_SITE_LINK: {
            let companyName = action.payload.company.id;
            let companyDetails = action.payload.company.details;
            return {
                ...state,
                uniqueCompanies: {
                    ...state.uniqueCompanies,
                    [companyName]: {
                        ...state.uniqueCompanies[companyName],
                        linkedSite: companyDetails.linkedSite,
                        linkedCompany: companyDetails.linkedCompany
                    }
                }
            }
        }

        case SET_SITE_LINK: {
            let companyName = action.payload.company.id;
            let companyDetails = action.payload.company.details;
            return {
                ...state,
                uniqueCompanies: {
                    ...state.uniqueCompanies,
                    [companyName]: {
                        ...state.uniqueCompanies[companyName],
                        linkedSite: companyDetails.linkedSite
                    }
                }
            }
        }
        case RESET_MAPPING_SCREEN: {
            return Object.assign({}, state, {
                mappings: {},
                uniqueCompanies: {},
                startLine: 0,
                selectedTemplate: null,
                templateName: ""
            })
        }       
        case RESET_COMPANY_LINKING_SCREEN: {
            return {
                ...state,
                uniqueCompanies: {}
            }
        }
        case SELECT_MAPPING_TEMPLATE: {
            return Object.assign({}, state, {
                templateName: action.payload.template
            })
        }
        default: {
            return state;
        }
    }
};        