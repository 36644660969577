export const SELECT_CONFIGURATION_TYPE = "SELECT_CONFIGURATION_TYPE";
export const SELECT_VENDOR = "SELECT_VENDOR";
export const SELECT_FILE = "SELECT_FILE";
export const ADD_DATA_PREVIEW = "ADD_DATA_PREVIEW";
export const ADD_CONFIGURATION_TYPE_QUESTIONS = "ADD_CONFIGURATION_TYPE_QUESTIONS";
export const ADD_CONFIGURATION_TYPES = "ADD_CONFIGURATION_TYPES";
export const ADD_VENDORS = "ADD_VENDORS";
export const TOGGLE_RENEWABLE = "TOGGLE_RENEWABLE";

export const SET_MAPPING = "SET_MAPPING";
export const REMOVE_MAPPING = "REMOVE_MAPPING";
export const LOAD_MAPPINGS = "LOAD_MAPPINGS";
export const ADD_UNIQUE_COMPANIES = "ADD_UNIQUE_COMPANIES";
export const SET_COMPANY_AND_SITE_LINK = "SET_COMPANY_AND_SITE_LINK";
export const SET_SITE_LINK = "SET_SITE_LINK";
export const RESET_MAPPING_SCREEN = "RESET_MAPPING_SCREEN";
export const RESET_COMPANY_LINKING_SCREEN = "RESET_COMPANY_LINKING_SCREEN";
export const RESET_SETUP_SCREEN = "RESET_SETUP_SCREEN";
export const SET_START_LINE = "SET_START_LINE";
export const SELECT_MAPPING_TEMPLATE = "SELECT_MAPPING_TEMPLATE";

export const SET_CONFIGS = "SET_CONFIGS";

export const SET_DUPES = "SET_DUPES";
export const SET_IMPORT_WITH_DUPLICATES = "SET_IMPORT_WITH_DUPLICATES";